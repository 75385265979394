import React, { useState, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import ApiConnection from "../common/ApiConnection";
import { Mixpanel } from "../auth/YsfMixPanel";
import Sidebar from "../common/Sidebar";
import PageLoader from "../common/PageLoader";
import Modal from "react-bootstrap/Modal";
import { IMAGE } from "../common/Theme";

const Subscription = () => {
  const stripeAll = useStripe();
  const [paymentType, setPaymentType] = useState("");
  const [subType, setSubType] = useState("");

  const [isWeeklyCoupon, setIsWeeklyCoupon] = useState(false);
  const [weeklyCoupon, setWeeklyCoupon] = useState("");
  const [weeklyCouponValidation, setWeeklyCouponValidation] = useState("");

  const [monthlyCoupon, setMonthlyCoupon] = useState("");
  const [monthlyCouponValidation, setMonthlyCouponValidation] = useState("");
  const [isMonthlyCoupon, setIsMonthlyCoupon] = useState(false);

  const [isQuaterlyCoupon, setIsQuaterlyCoupon] = useState(false);
  const [quaterlyCoupon, setQuaterlyCoupon] = useState("");
  const [quaterlyCouponValidation, setQuaterlyCouponValidation] = useState("");

  const [billinPlan, setBillinPlan] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [changePlanEffetiveDate, setChangePlanEffetiveDate] = useState("");
  const [changePlanStartDay, setChangePlanStartDay] = useState(0);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const weeklyCouponRef = useRef(null);
  const monthlyCouponRef = useRef(null);
  const quaterlyCouponRef = useRef(null);
  const btnMonthlyCouponRef = useRef(null);

  var user_email = localStorage.getItem("ysf_email");
  var subscriptionType = localStorage.getItem("JobApp_SubscriptionType");
  const isProd = process.env.REACT_APP_IS_PROD;
  var navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [upgradePlanData, setUpgradePlanData] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [successModalShow, setSuccessModalShow] = useState(false);

  useEffect(() => {
    const getDueDate = async () => {
      try {
        let getSubscriptionObj = {
          UserEmail: user_email,
        };
        setLoading(true);
        const response = await ApiConnection.post(
          "api/JobbsprangetSubscription/JobappUserSubscription",
          getSubscriptionObj
        );
        if (response.data?.isSuccess) {
          setSubscriptionId(response.data?.subscriptionId);
          setBillinPlan(response.data?.billingPlan);
          setRenewalDate(formatDate(response.data?.renewalCancelDate));
          setSubscriptionStatus(response.data?.billingPlanStatus);
          setLoading(false);
        } else {
          console.log("No");
          setSubscriptionStatus(response.data?.billingPlanStatus);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error in Getting Subscription Status!!");
        setLoading(false);
      }
    };
    getDueDate();
  }, [user_email]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    // Format the date using toLocaleDateString
    return date.toLocaleDateString("en-US", {
      day: "2-digit", // 'numeric' for single digit days
      month: "long", // Use 'short' for abbreviated month (e.g., "Sep")
      year: "numeric",
    });
  }
  function DiffDate(dateString) {
    const currentDate = new Date();
    console.log("currentDate#" + currentDate);
    const futurDate = new Date(dateString);
    console.log("futurDate#" + futurDate);
    const diffTime = Math.abs(futurDate - currentDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    console.log("diffDays#" + diffDays);
    console.log("days#" + diffDays);
    return diffDays;
  }

  const btnWeeklyPaymentHandle = async (event) => {
    const btnPaymentPlanText = event.target.innerText;
    let priceId = "price_1PXj3aJEaxc25mG4unU8s0v6";
    let subPlanType = 1;
    if (isProd === "Y") priceId = "price_1PXllqJEaxc25mG4VcSsRJMb";

    if (btnPaymentPlanText === "Change Plan") {
      setUpgradePlanData("");
      const changePlanValue = {
        subPlanType: subPlanType,
        priceId: priceId,
        coupon: weeklyCoupon,
        newPlanName: "1 Week",
        planCost: "€3.9",
        newPlanTxt: "New Weekly Cost: ",
      };
      setUpgradePlanData(changePlanValue);
      setModalShow(true);
      return;
    }
    setModalShow(false);

    if (!stripeAll) {
      return;
    }

    PaymentHandle(subPlanType, priceId, weeklyCoupon);
    // if (isProd === "N")
    //   PaymentHandle(1, "price_1PXj3aJEaxc25mG4unU8s0v6", weeklyCoupon);
    // else PaymentHandle(1, "price_1PXllqJEaxc25mG4VcSsRJMb", weeklyCoupon);
  };

  const btnMonthlyPaymentHandle = async (event) => {
    const btnPaymentPlanText = event.target.innerText;
    let priceId = "price_1PQPALJEaxc25mG4LKxN6Fxa";
    let subPlanType = 2;
    if (isProd === "Y") priceId = "price_1PXjoaJEaxc25mG43Y1T9b9T";

    if (btnPaymentPlanText === "Change Plan") {
      setUpgradePlanData("");
      const changePlanValue = {
        subPlanType: subPlanType,
        priceId: priceId,
        coupon: monthlyCoupon,
        newPlanName: "4 Weeks",
        planCost: "€2.9",
        newPlanTxt: "New Weekly Cost:",
      };
      setUpgradePlanData(changePlanValue);
      setModalShow(true);
      return;
    }
    setModalShow(false);
    if (!stripeAll) {
      return;
    }

    PaymentHandle(subPlanType, priceId, monthlyCoupon);

    // if (isProd === "N")
    //   PaymentHandle(2, "price_1PQPALJEaxc25mG4LKxN6Fxa", monthlyCoupon);
    // else PaymentHandle(2, "price_1PXjoaJEaxc25mG43Y1T9b9T", monthlyCoupon);
  };

  const btnQuaterlyPaymentHandle = async (event) => {
    const btnPaymentPlanText = event.target.innerText;
    let priceId = "price_1PQPQyJEaxc25mG44fFoeIc2";
    let subPlanType = 3;
    if (isProd === "Y") priceId = "price_1PXjpDJEaxc25mG4zfadyAuz";

    if (btnPaymentPlanText === "Change Plan") {
      setUpgradePlanData("");
      const changePlanValue = {
        subPlanType: subPlanType,
        priceId: priceId,
        coupon: quaterlyCoupon,
        newPlanName: "12 Weeks",
        planCost: "€1.9",
        newPlanTxt: "New Weekly Cost:",
      };
      setUpgradePlanData(changePlanValue);
      setModalShow(true);
      return;
    }
    setModalShow(false);

    if (!stripeAll) {
      console.log("stripe not enabled!!");
      return;
    }

    PaymentHandle(subPlanType, priceId, quaterlyCoupon);

    // if (isProd === "N")
    //   PaymentHandle(3, "price_1PQPQyJEaxc25mG44fFoeIc2", quaterlyCoupon);
    // else PaymentHandle(3, "price_1PXjpDJEaxc25mG4zfadyAuz", quaterlyCoupon);
  };

  const PaymentHandle = async (_paymentType, _priceId, _coupon) => {
    console.log("userEmail" + user_email);
    console.log("_priceId" + _priceId);
    console.log("_paymentType" + _paymentType);
    console.log("_coupon#" + _coupon);
    try {
      // if (parseInt(subscriptionType) > 0 && parseInt(subscriptionType) < 4) {
      //   setSubType("You Aleady have active subscription!!");
      //   return;
      // }
      setSubType("");
      if (_coupon) {
        let getfreeTrialCouponObj = {
          Couponcode: _coupon,
          UserEmail: user_email,
        };
        const response = await ApiConnection.post(
          "/JobbsprangetCoupon/FreeTrialCouponValidation",
          getfreeTrialCouponObj
        );
        if (response.data?.isSuccess) {
          Mixpanel.track(
            `YsfJobApp:FreeTrial Subscription for:${
              _paymentType === 1
                ? "1 Week"
                : _paymentType === 2
                ? "4 Weeks"
                : "12 Weeks"
            }`
          );
          setConfirmModalShow(true);
          return;
        }
      }

      if (_coupon === "") _coupon = "";
      switch (_paymentType) {
        case 1:
          if (!isWeeklyCoupon) _coupon = "";
          break;
        case 2:
          if (!isMonthlyCoupon) _coupon = "";
          break;
        case 3:
          if (!isQuaterlyCoupon) _coupon = "";
          break;
        default:
      }

      let paymentRequest = {
        PriceId: _priceId,
        Quantity: 1,
        CouponId: _coupon,
        PaymentType: _paymentType,
        UserEmail: user_email,
        SuccessGift: 1,
      };
      const {
        data: { id, email },
      } = await ApiConnection.post(
        "/api/JobbsprangetPayment/create-checkout-session",
        paymentRequest
      );
      const { error } = await stripeAll.redirectToCheckout({
        sessionId: id,
      });
      if (error) {
        console.error("Stripe Checkout error:", error);
      } else {
        //Mixpanel.track('YsfJobApp:PayNow:'+_paymentType+"#"+email+"#"+_coupon);
        Mixpanel.track(
          `YsfJobApp:PayNow:${
            _paymentType === 1
              ? "1 Week"
              : _paymentType === 2
              ? "4 Weeks"
              : "12 Weeks"
          } '#' ${email} '#'${_coupon}`
        );
      }
    } catch (ex) {
      console.log("Error in subscription!!");
    }
  };

  const SubscribeType = (subType) => {
    setPaymentType(subType);
  };

  const ApplyCoupon = async () => {
    let coupon;
    try {
      switch (paymentType) {
        case "1":
          coupon = weeklyCoupon;
          if (weeklyCoupon.length === 0) {
            coupon = "";
            weeklyCouponRef.current.focus();
            setWeeklyCouponValidation("Please enter coupon code!!");
            return;
          }
          break;
        case "2":
          coupon = monthlyCoupon;
          if (monthlyCoupon.length === 0) {
            coupon = "";
            monthlyCouponRef.current.focus();
            setMonthlyCouponValidation("Please enter coupon code!!");
            return;
          }
          break;
        case "3":
          coupon = quaterlyCoupon;
          if (quaterlyCoupon.length === 0) {
            coupon = "";
            quaterlyCouponRef.current.focus();
            setQuaterlyCouponValidation("Please enter coupon code!!");
            return;
          }
          break;
        default:
          // coupon = JobApp_ReferId;
          // setPaymentType("2");
          break;
      }
      let validateCoupon = {
        Couponcode: coupon.toUpperCase(),
        CouponPlan: paymentType.length === 0 ? "2" : paymentType,
        UserEmail: user_email,
      };
      const response = await ApiConnection.post(
        "/JobbsprangetCoupon/CouponValidation",
        validateCoupon
      );
      switch (paymentType) {
        case "1":
          setWeeklyCouponValidation(response.data?.couponDurationTxt);
          if (
            response.data.isSuccess &&
            (response.data.couponMsgType === 4 ||
              response.data.couponMsgType === 5)
          ) {
            setIsWeeklyCoupon(true);
            Mixpanel.track(
              "YsfJobApp:ApplyCoupon:Weekly:" + user_email + "#" + coupon
            );
          } else {
            setIsWeeklyCoupon(false);
            setWeeklyCoupon("");
          }
          break;
        case "2":
          setMonthlyCouponValidation(response.data?.couponDurationTxt);
          if (
            response.data.isSuccess &&
            (response.data.couponMsgType === 4 ||
              response.data.couponMsgType === 5)
          ) {
            setIsMonthlyCoupon(true);
            Mixpanel.track(
              "YsfJobApp:ApplyCoupon:Monthly:" + user_email + "#" + coupon
            );
          } else {
            setIsMonthlyCoupon(false);
            setMonthlyCoupon("");
          }
          break;
        case "3":
          setQuaterlyCouponValidation(response.data?.couponDurationTxt);
          if (
            response.data.isSuccess &&
            (response.data.couponMsgType === 4 ||
              response.data.couponMsgType === 5)
          ) {
            setIsQuaterlyCoupon(true);
            Mixpanel.track(
              "YsfJobApp:ApplyCoupon:Quaterly:" + user_email + "#" + coupon
            );
          } else {
            setIsQuaterlyCoupon(false);
            setQuaterlyCoupon("");
          }
          break;
        default:
          setMonthlyCouponValidation(response.data?.couponDurationTxt);
          if (response.data.isSuccess) {
            setIsMonthlyCoupon(true);
            Mixpanel.track(
              "YsfJobApp:ApplyCoupon:Monthly:" + user_email + "#" + coupon
            );
          }
          break;
      }
    } catch (err) {
      console.log("Error in ApplyCoupon!!");
    }
  };

  const SetCouponVal = async (couponCode) => {
    switch (paymentType) {
      case "1":
        if (couponCode.length === 0) setWeeklyCouponValidation("");
        setWeeklyCoupon(couponCode);
        break;
      case "2":
        if (couponCode.length === 0) setMonthlyCouponValidation("");
        setMonthlyCoupon(couponCode);
        break;
      case "3":
        if (couponCode.length === 0) setQuaterlyCouponValidation("");
        setQuaterlyCoupon(couponCode);
        break;
      default:
    }
  };

  const handleKeyDown = (event) => {
    console.log("event#" + event.key);
    if (event.key === "Enter") ApplyCoupon();
  };
  const handleClose = () => {
    setIsButtonDisabled(true);
    setModalShow(false);
  };
  const handleChangePlan = async () => {
    try {
      setIsButtonDisabled(false);
      console.log("upgradePlanData" + upgradePlanData.subPlanType);
      let getUpgradPlaneObj = {
        UserEmail: user_email,
        SubscriptionId: subscriptionId,
        SubscriptionPlanType: subscriptionType,
        ChangeSubscriptionPlanType: upgradePlanData.subPlanType,
        ChangePriceId: upgradePlanData.priceId,
        CouponCode: upgradePlanData.coupon.toUpperCase(),
      };

      const response = await ApiConnection.post(
        "api/JobbsprangetPayment/UpgradeSubscriptionPlan",
        getUpgradPlaneObj
      );
      if (response.data?.isSuccess) {
        setModalShow(false);
        setIsButtonDisabled(true);
        setSuccessModalShow(true);
      }
    } catch (error) {
      console.log("Error in Updagrading!!");
      setSuccessModalShow(false);
      setModalShow(false);
      setIsButtonDisabled(true);
    }
  };

  const handleGoToHome = () => {
    setSuccessModalShow(false);
    navigate("/home");
    window.location.reload();
  };
  const hndFreeTrial = async (value) => {
    sessionStorage.removeItem("JobApp_ReferId");
    setConfirmModalShow(value);
    navigate("/home");
    window.location.reload();
  };

  return (
    <div className="common-panel home-page">
      <div className="wallet-container">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-xxl-2">
            <Sidebar />
          </div>
          <div className="col-lg-9 col-xl-9 col-xxl-10">
            <div className="subscription-container">
              <div className="subscription-innercontainer">
                {loading && <PageLoader />}

                {subscriptionStatus === "canceled" && (
                  <div className="issubscribe">
                    <h5>
                      Your current subscription <span> UNSUBSCRIBED </span>
                    </h5>
                    <p>
                      Your billing cycle will end on {renewalDate}
                      <br></br>
                      <>
                        You can <NavLink>resubscribe</NavLink> here.
                      </>
                    </p>
                    <hr />
                  </div>
                )}

                {subscriptionStatus && subscriptionStatus !== "canceled" && (
                  <div className="issubscribe">
                    <h5>
                      Your current subscription <span>{billinPlan}</span>
                    </h5>
                    <p>
                      Your next payment will be charged on {renewalDate}
                      <br></br>
                      <>
                        You can{" "}
                        <NavLink to="/CancelSubscription">
                          cancel your subscription
                        </NavLink>{" "}
                        here.
                      </>
                    </p>
                    <hr />
                  </div>
                )}

                {parseInt(subscriptionType) > 0 && (
                  <div className="isnotsubscribe">
                    <h3>Subscription Plan</h3>
                  </div>
                )}
                {parseInt(subscriptionType) === 0 && (
                  <div className="isnotsubscribe">
                    <h3>Supercharge your career</h3>
                    <h6>
                      See your dream job calling? Click "Try Now" to unlock your
                      resume's full potential.
                    </h6>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className={
                        parseInt(subscriptionType) === 1
                          ? "subscription-box active"
                          : "subscription-box"
                      }
                      onClick={() => SubscribeType("1")}
                    >
                      {parseInt(subscriptionType) === 1 && (
                        <label>Active</label>
                      )}
                      <h4>1 Week</h4>
                      <p>No cancellation fee.</p>
                      <span>
                        €3.4 <sub>/week</sub>
                      </span>
                      <div className="coupon-form">
                        <input
                          type="text"
                          maxLength="10"
                          ref={weeklyCouponRef}
                          value={weeklyCoupon}
                          onChange={(e) =>
                            SetCouponVal(e.target.value.toUpperCase())
                          }
                          onKeyDown={handleKeyDown}
                          placeholder="COUPON CODE"
                          className="form-control"
                        />
                        <button className="ApplynowBtn" onClick={ApplyCoupon}>
                          Apply now
                        </button>
                      </div>
                      <p>{weeklyCouponValidation}</p>
                      <button
                        type="submit"
                        onClick={btnWeeklyPaymentHandle}
                        className="TrynowBtn"
                        disabled={!stripeAll}
                      >
                        {parseInt(subscriptionType) > 0 &&
                        parseInt(subscriptionType) < 4
                          ? parseInt(subscriptionType) === 1
                            ? "Current Plan"
                            : "Change Plan"
                          : "Subscribe Now!"}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div
                      className={
                        parseInt(subscriptionType) === 2
                          ? "subscription-box active"
                          : "subscription-box"
                      }
                      onClick={() => SubscribeType("2")}
                    >
                      {parseInt(subscriptionType) === 2 && (
                        <label>Active</label>
                      )}
                      <h4>4 Weeks</h4>
                      <p>No cancellation fee.</p>
                      <span>
                        €2.9 <sub>/week</sub>
                      </span>
                      <div className="coupon-form">
                        <input
                          type="text"
                          maxLength="10"
                          ref={monthlyCouponRef}
                          value={monthlyCoupon}
                          onChange={(e) =>
                            SetCouponVal(e.target.value.toUpperCase())
                          }
                          onKeyDown={handleKeyDown}
                          placeholder="COUPON CODE"
                          className="form-control"
                        />
                        <button
                          className="ApplynowBtn"
                          ref={btnMonthlyCouponRef}
                          onClick={ApplyCoupon}
                        >
                          Apply now
                        </button>
                      </div>
                      <p>{monthlyCouponValidation}</p>
                      <button
                        type="submit"
                        className="TrynowBtn"
                        onClick={btnMonthlyPaymentHandle}
                        disabled={!stripeAll}
                      >
                        {parseInt(subscriptionType) > 0 &&
                        parseInt(subscriptionType) < 4
                          ? parseInt(subscriptionType) === 2
                            ? "Current Plan"
                            : "Change Plan"
                          : "Subscribe Now!"}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div
                      className={
                        parseInt(subscriptionType) === 3
                          ? "subscription-box active"
                          : "subscription-box"
                      }
                      onClick={() => SubscribeType("3")}
                    >
                      {parseInt(subscriptionType) === 3 && (
                        <label>Active</label>
                      )}
                      <h4>12 Weeks</h4>
                      <label>Save 45%</label>
                      <p>No cancellation fee.</p>
                      <span>
                        €1.9 <sub>/week</sub>
                      </span>
                      <div className="coupon-form">
                        <input
                          type="text"
                          maxLength="10"
                          ref={quaterlyCouponRef}
                          value={quaterlyCoupon}
                          onChange={(e) =>
                            SetCouponVal(e.target.value.toUpperCase())
                          }
                          onKeyDown={handleKeyDown}
                          placeholder="COUPON CODE"
                          className="form-control"
                        />
                        <button onClick={ApplyCoupon} className="ApplynowBtn">
                          Apply now
                        </button>
                      </div>
                      <p>{quaterlyCouponValidation}</p>
                      <button
                        type="submit"
                        className="TrynowBtn"
                        onClick={btnQuaterlyPaymentHandle}
                        disabled={false}
                      >
                        {parseInt(subscriptionType) > 0 &&
                        parseInt(subscriptionType) < 4
                          ? parseInt(subscriptionType) === 3
                            ? "Current Plan"
                            : "Change Plan"
                          : "Subscribe Now!"}
                      </button>
                    </div>
                  </div>
                </div>
                <h3 style={{ color: "green" }}>{subType}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="subscription-modal language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={IMAGE.Happy_Announcement}
                className="w-100"
                alt="success_img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="confirm-modal-content"
                style={{ paddingLeft: "30px" }}
              >
                <h4>Confirm Plan Change</h4>
                <p>
                  You're switching to <b> {upgradePlanData.newPlanName}</b>{" "}
                  plan.
                </p>

                {/* Your new plan will start in <b> {"[X days]"}</b> when
                  your current plan ends. Effective Date:<b> {"Date"}</b>
               */}
                <ol className="plan-bulet">
                  <li>
                    {upgradePlanData.newPlanTxt}{" "}
                    <b> {upgradePlanData.planCost}</b>{" "}
                  </li>
                  {/* <li>
                    {" "}
                    Effective Date: <b> {changePlanEffetiveDate}</b>{" "}
                  </li> */}
                </ol>
                <div className="d-flex mt-4">
                  {isButtonDisabled ? (
                    <button
                      className="BtnStyle_three"
                      onClick={handleChangePlan}
                    >
                      Change Plan
                    </button>
                  ) : (
                    <button className="BtnStyle_three">
                      Proceesing<i className="fa fa-spinner fa-spin"></i>
                    </button>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button className="BtnStyle_three" onClick={handleClose}>
                    Canel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={successModalShow}
        aria-labelledby="contained-modal-title-vcenter"
        className="language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            <div>
              <div
                className="confirm-modal-content"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                <h4 style={{ textAlign: "center" }}>
                  Plan Updated Successfully!
                </h4>

                <div className="d-flex mt-3">
                  <p>
                    Your subscription plan has been changed to{" "}
                    <b>{upgradePlanData.newPlanName}</b>. Enjoy the new features
                    and benefits that come with your plan. If you have any
                    questions or need assistance, feel free to contact our
                    support team.
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <button className="BtnStyle_three" onClick={handleGoToHome}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="subscription-modal language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={IMAGE.success_img}
                className="w-100"
                alt="success_img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="confirm-modal-content"
                style={{ paddingLeft: "30px" }}
              >
                <h4>Congratulations!</h4>
                <p>
                  You're activating your free package! Your subscription will
                  renew, and soon you can invite friends for extended premium
                  membership. Awesome!
                </p>
                <div className="d-flex mt-4">
                  <button
                    className="BtnStyle_two mx-2"
                    onClick={() => hndFreeTrial(false)}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscription;
