import { Outlet, Navigate } from "react-router-dom";
const PrivateRoute = () => {
  const appVersion = process.env.REACT_APP_VERSION;
  const storedAppVersion = localStorage.getItem("jobaApp_version");
  console.log("appVersion#" + appVersion);
  console.log("storedAppVersion#" + storedAppVersion);
  var user_email = "";
  if (appVersion !== storedAppVersion) {
    user_email = null;
    console.log("Not matched#");
    localStorage.setItem("jobaApp_version", appVersion);
    // Clear user session and cached data
    localStorage.removeItem("email_verified");
    localStorage.removeItem("ysf_email");
    localStorage.removeItem("ysf_user");
    localStorage.removeItem("ysf_name");
    localStorage.removeItem("fname");
    localStorage.removeItem("DaysLeft");
    localStorage.removeItem("license");
    localStorage.removeItem("JobApp_SubscriptionType");
    localStorage.removeItem("JobApp_UserId");
    sessionStorage.removeItem("JobApp_ReferId");
    localStorage.removeItem("JobappUserId");
    localStorage.removeItem("JobApp_subscriptionEndDate");
    localStorage.removeItem("JobApp_IsSubscribe");
  } else {
    console.log("Matched#");
    user_email = localStorage.getItem("ysf_email");
  }

  return user_email !== null ? <Navigate to="/home" /> : <Outlet />;
};

export default PrivateRoute;
