import React, { useEffect, useState } from "react";
import { IMAGE } from "../common/Theme";
import ApiConnection from "../common/ApiConnection";
import Sidebar from "../common/Sidebar";
import NodataListing from "../common/NoDataListing";
import PageLoader from "../common/PageLoader";

var user_email = localStorage.getItem("ysf_email");
//console.log("#SubscriptionId#"+SubscriptionId);
const Invoices = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [billinPlan, setBillinPlan] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);

  useEffect(() => {
    const getInvoiceList = async () => {
      setLoading(true);
      try {
        let getInvoiceObj = {
          UserEmail: user_email,
        };
        const response = await ApiConnection.post(
          "/Invoice/JobappInvoicedownload",
          getInvoiceObj
        );
        var InvoiceArray = [];
        setInvoiceList(InvoiceArray);
        if (response.data?.isSuccess && response.data?.invoice) {
          var _invoiceList = response.data?.invoice;
          setIsSubscription(response.data?.billing.isSubscription);
          setBillinPlan(response.data?.billing.billingPlan);
          setRenewalDate(formatDate(response.data?.billing.billingPeriod));
          setTotalAmount("€" + response.data?.billing.totalAmount);

          _invoiceList?.forEach((invoice) => {
            if (invoice.status === "paid") {
              const formattedStartDate = formatDate(
                invoice.subscriptionStartDate
              );
              const formattedEndDate = formatDate(invoice.subscriptionEndDate);
              const invoicePeriod = `${formattedStartDate}-${formattedEndDate}`;
              // Check if an invoice with the same period already exists in the array
              const periodExists = InvoiceArray.some(
                (inv) => inv.invoicePeriod === invoicePeriod
              );
              if (!periodExists) {
                InvoiceArray.push({
                  invoiceNo: invoice.invoiceNo,
                  inv: invoice.invoicePdfUrl,
                  invDesc: invoice.invoiceDesc,
                  status: invoice.status,
                  createdDate: invoice.createdDate,
                  invoicePeriod:
                    formatDate(invoice.subscriptionStartDate) +
                    " - " +
                    formatDate(invoice.subscriptionEndDate),
                });
              }
            } else if (
              invoice.status === "draft" &&
              invoice.invoiceDesc != null
            ) {
              InvoiceArray.push({
                inv: invoice.invoicePdfUrl,
                invDesc: invoice.invoiceDesc,
                status: invoice.status,
                createdDate: invoice.createdDate,
                invoicePeriod: "---",
              });
            }
          });

          setInvoiceList(InvoiceArray);
          setLoading(false);
        } else {
          console.log("No");
          setLoading(false);
        }
      } catch (error) {
        console.log("Error in Invoice List!!");
        setLoading(false);
      }
    };
    getInvoiceList();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format the date using toLocaleDateString
    return date.toLocaleDateString("en-US", {
      day: "2-digit", // 'numeric' for single digit days
      month: "long", // Use 'short' for abbreviated month (e.g., "Sep")
      year: "numeric",
    });
  }

  return (
    <div className="common-panel home-page">
      <div className="wallet-container">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-xxl-2">
            <Sidebar />
          </div>
          <div className="col-lg-9 col-xl-9 col-xxl-10">
            <div className="billing-sec">
              <h3>Billing</h3>{" "}
              {loading ? (
                <PageLoader />
              ) : invoiceList.length > 0 ? (
                <>
                  <div className="sec-list">
                    <h4>Billing</h4>
                    <div className="sec-list-right">
                      <ul>
                        <li>
                          <b>Billing Period</b>
                          <span>{billinPlan}</span>
                        </li>
                        <li>
                          {isSubscription ? (
                            <b>Renewal date</b>
                          ) : (
                            <b>Cancel date</b>
                          )}
                          <span>{renewalDate}</span>
                        </li>
                        <li>
                          <b>Total</b>
                          <span>{totalAmount} </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="sec-list"
                    style={{ alignItems: "center", display: "none" }}
                  >
                    <h4>Payment method</h4>
                    <div className="sec-list-right paymentchange">
                      <h5>
                        <img src={IMAGE.Visa} alt="" /> Visa card ended with
                        *3041
                      </h5>
                      <button>Change Payment Method</button>
                    </div>
                  </div>
                  <div className="sec-list">
                    <h4>Invoice</h4>
                    <div className="sec-list-right">
                      <div className="table-responsive table-style">
                        <table>
                          <thead>
                            <th>S.No.</th>
                            <th>Invoice period</th>
                            <th>Status</th>
                            <th>Download</th>
                          </thead>
                          <tbody>
                            {invoiceList &&
                              invoiceList.map((list, index) => {
                                return (
                                  <tr key={index}>
                                    <td> {index + 1}</td>
                                    <td> {list?.invoicePeriod}</td>
                                    <td> {list?.status}</td>
                                    {list?.inv == null ? (
                                      <td key={index}>{list?.invDesc}</td>
                                    ) : (
                                      <td key={index}>
                                        <u>
                                          {" "}
                                          <a
                                            className=""
                                            href={list?.inv}
                                            alt="Download invoice"
                                          >
                                            {list?.invoiceNo}
                                          </a>{" "}
                                        </u>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NodataListing />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Invoices;
