import React from "react";
import { IMAGE } from "../common/Theme";
import { NavLink } from "react-router-dom";

const ConfirmCancelSubscription = () => {
  return (
    <div className="common-panel home-page success-box">
      <div className="home-container">
        <div className="subscription-container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={IMAGE.Happy_Announcement} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 text-left">
              <img src={IMAGE.smile_icon} alt="" />
              <h3>Great choice!</h3>
              <p>
                Stay excellent, keep your CV standing out and your <br></br>{" "}
                interviews flawless.
              </p>

              <ul className="success-box_btn">
                <li>
                  <NavLink className="BtnStyle_two" to="/home">
                    Close
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmCancelSubscription;
