import React, { useEffect, useState } from "react";
import { IMAGE } from "../../common/Theme";
import { FileUploader } from "react-drag-drop-files";
import Nodata from "../../common/Nodata";
import Loader from "../../common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  StepAction,
  JobTitleAction,
  JobpostUrlAction,
  JobPostIdAction,
} from "../../reducer/Dataflowreducer";
import Modal from "react-bootstrap/Modal";
import ApiConnection from "../../common/ApiConnection";
import FormdataApi from "../../common/FormdataApi";
import { NavLink } from "react-router-dom";
import ConcernLoader from "../concern_gain/ConcernLoader";
import { Mixpanel } from "../../auth/YsfMixPanel";
import copy from "copy-to-clipboard";

const JobProfile = () => {
  const [file, setFile] = useState(null);
  const [isfile, setisfile] = useState("");
  const [fileName, setFileName] = useState("");
  const [list, setlist] = useState([]);
  const [rowId, setrowId] = useState(null);
  const [showmodal, setshowmodal] = useState(false);
  const [cvTxt, setCVTxt] = useState("");
  const [linkdinUrl, setLinkdinUrl] = useState("");
  const [enablegenateBtn, setenablegenateBtn] = useState(false);
  const [isactivecvfield, setisactivecvfield] = useState(true);
  const [isactiveurlfield, setisactiveurlfield] = useState(true);
  const [loading, setloading] = useState(false);
  const [nodata, setnodata] = useState(true);
  const [output, setoutput] = useState(false);
  const [uploadLoader, setuploadLoader] = useState(false);
  const [isShowGenerateBtn, setIsShowGenerateBtn] = useState(false);
  const [activeTab, setactiveTab] = useState("cv");
  const [bodytext, setbodytext] = useState("");
  const [introInitialTxt, setIntroInitialTxt] = useState("");
  const [introtext, setintrotext] = useState("");
  const [conclusionTxt, setConclusionTxt] = useState("");

  const [isCoverletterloading, setIsCoverletterloading] = useState(true);
  const [jobPostId, setJobPostId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobPostUrl, setJobPostUrl] = useState("");
  const [goToConcern, setGoToConcern] = useState("");
  const [isShowSaveFinishBtn, setIsShowSaveFinishBtn] = useState(false);
  const fetchReducer = useSelector((state) => state.dataflowreducer);
  const [getError, setGetError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);
  const [feedback, setFeedback] = useState(false);

  var user_email = localStorage.getItem("ysf_email");
  var user_name = localStorage.getItem("fname");
  var jobAppLang = localStorage.getItem("jobAppLang");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  var srNoregex = new RegExp("\\d+\\.", "g");
  var splitText = /\n\d+\.\s/;
  useEffect(() => {
    try {
      if (fetchReducer?.jobInterviewPreparationDone) {
        GetUserFeedbackCoverletter();
        setFeedback(true);
      }
    } catch (error) {
      console.log("Error!!!");
    } finally {
      Mixpanel.identify(user_email);
      if (user_name) {
        Mixpanel.people.set({
          $first_name: user_name,
        });
      }
    }
  }, []);

  const GetUserFeedbackCoverletter = async () => {
    try {
      let feedbackCoverletterObj = {
        UserEmail: user_email,
        JobPostURL: fetchReducer?.url,
        JobPostId: fetchReducer?.jobpostId,
      };
      const feedBackCoverLetterResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGetCVCoverLetterFeedback",
        feedbackCoverletterObj
      );
      if (feedBackCoverLetterResponse.data?.isSuccess) {
        setnodata(false);
        setoutput(true);
        setGetError(false);
        var feedbackData = feedBackCoverLetterResponse.data?.jobPostFeedBack;
        populateCVFeedback(feedbackData);

        //CoverLetter
        var coverLetterResponseData =
          feedBackCoverLetterResponse.data?.userCVCoverLetter;
        populateCVCoverLetter(coverLetterResponseData);

        // Set CV OR LINKEDIN URL
        var cvName = feedBackCoverLetterResponse.data?.userCVName;
        if (cvName) {
          setisfile(cvName);
          setFileName(cvName);
          setGoToConcern(1);
          setIsShowGenerateBtn(true);
          setIsShowSaveFinishBtn(false);
        }
        var linkedinUrl = feedBackCoverLetterResponse.data?.userLinkedinUrl;
        if (linkedinUrl) {
          setLinkdinUrl(linkedinUrl);
          setisfile("");
          setFileName("");
        }
      }
    } catch (e) {
      console.log("Error in loadingdata");
    }
  };

  const fileTypes = ["DOC", "PDF", "DOCX"];
  const CVUploadHandleChange = async (file) => {
    setFile(file);
    setIsShowGenerateBtn(false);
    if (file) {
      setuploadLoader(true);

      setisactiveurlfield(false);
      setenablegenateBtn(true);
      setFileName(file.name);

      try {
        let data = new FormData();
        data.append("files", file);
        const response = await FormdataApi.post(
          "/JobbsprangetFileUploadUser/ReadDocFile",
          data
        );
        if (response) {
          if (
            feedback ||
            fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
            fetchReducer.goToConceren === "CVFeedbackGenerated"
          ) {
            setGoToConcern(1);
          } else {
            setGoToConcern(2);
          }

          setuploadLoader(false);
          setIsShowGenerateBtn(true);
          setCVTxt(response.data);
        }
      } catch (err) {
        setuploadLoader(false);
      }
    } else {
      setisactiveurlfield(true);
      setenablegenateBtn(false);
    }
  };
  const linkdinUrlHandleInput = (value) => {
    setLinkdinUrl(value);
    if (value === "") {
      setIsShowGenerateBtn(false);
      setisactivecvfield(true);
      setenablegenateBtn(false);
      setLinkedinError(false);
    } else {
      if (
        feedback ||
        fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
        fetchReducer.goToConceren === "CVFeedbackGenerated"
      ) {
        setGoToConcern(1);
      } else {
        setGoToConcern(2);
      }
      setIsShowGenerateBtn(true);
      setisactivecvfield(false);
      setenablegenateBtn(true);
    }
  };

  const DeleteHandle = () => {
    setisfile("");
    setisactiveurlfield(true);
    setenablegenateBtn(false);
    setIsShowGenerateBtn(false);
  };

  const NextAction = async (step) => {
    try {
      let cvSelectedFeedbackStr = "";
      var separator = "||";
      list.forEach((element) => {
        if (element.toggleSelected) {
          cvSelectedFeedbackStr += element.text + separator;
        }
      });

      let userLinkedinCVObj = {
        UserEmail: user_email,
        JobPostUserLinkedinURL: linkdinUrl,
        JobPostUserCV: "",
        JobPostUserFeedBack: cvSelectedFeedbackStr,
        JobPostIsCV: linkdinUrl !== "" ? 0 : 1,
        JobPostURL: fetchReducer?.url,
        JobPostUserCVName: fileName,
        JobPostId: fetchReducer?.jobpostId,
      };

      const response = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetJobCVUploadApi",
        userLinkedinCVObj
      );

      if (response.data?.isSuccess) {
        dispatch(StepAction(step));
        navigate("/home");
      }
    } catch (err) {
      console.log("Error!");
    }
  };

  const SelectHandle = (row) => {
    const updateData = list.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      };
    });

    setlist(updateData);
  };

  const ModalShow = (id) => {
    setrowId(id);
    setshowmodal(true);
  };

  const DlistHandle = () => {
    const newList = list.filter((item) => item.id !== rowId);
    setshowmodal(false);
    setlist(newList);
  };

  const BtnGenerateFeedbackHnd = async (isError) => {
    let cvLinkedinUrlFeedbackObj = "";
    let cvLinkedinUrlCoverLetterObj = "";
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      if (linkdinUrl.trim() !== "") {
        var myLinkedinUrlRegEx = new RegExp(
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
        );
        myLinkedinUrlRegEx = new RegExp(
          /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
        );

        if (!myLinkedinUrlRegEx.test(linkdinUrl.trim())) {
          setLinkedinError(true);
          return;
        }
        setLinkedinError(false);

        // Linkedin FEEDBACK Request
        cvLinkedinUrlFeedbackObj = {
          JobPostUserLinkedinURL: linkdinUrl.trim(),
          UserEmail: user_email,
          JobPostURL: fetchReducer?.url,
          JobPostUserCV: "",
          JobPostUserCVName: "",
          JobPostId: fetchReducer?.jobpostId,
          Language: jobAppLang,
        };
      } else {
        //CV FEEDBACK Request
        cvLinkedinUrlFeedbackObj = {
          JobPostUserLinkedinURL: "",
          UserEmail: user_email,
          JobPostURL: fetchReducer?.url,
          JobPostUserCV: cvTxt,
          JobPostUserCVName: fileName,
          JobPostId: fetchReducer?.jobpostId,
          Language: jobAppLang,
        };
      }
      //COVER LETTER
      cvLinkedinUrlCoverLetterObj = {
        UserEmail: user_email,
        JobPostURL: fetchReducer?.url,
        JobPostId: fetchReducer?.jobpostId,
        Language: jobAppLang,
      };

      //Loading page
      setnodata(false);
      setloading(true);
      setoutput(false);
      setIsCoverletterloading(true);
      if (isError) {
        setGetError(false);
      }
      //console.log("cvLinkedinUrlFeedbackObj#"+JSON.stringify(cvLinkedinUrlFeedbackObj));
      // GET FEEDBACK RESPONSE
      const feedbackResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetUserLinkedinURL",
        cvLinkedinUrlFeedbackObj
      );
      var feedbackResponseData = feedbackResponse?.data;
      var isFeedbackError = feedbackResponseData.includes(
        "TimeOut Error: Try Again"
      );
      if (isFeedbackError) {
        setloading(false);
        setnodata(true);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVFeedback(feedbackResponseData);

      //COVER LETTER RESPONSE
      const coverletterResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateCoverletterApi",
        cvLinkedinUrlCoverLetterObj
      );
      //console.log("cvLinkedinUrlCoverLetterObj#"+JSON.stringify(cvLinkedinUrlCoverLetterObj));
      var coverLetterResponseData = coverletterResponse?.data?.jobPostReponse;
      var isCoverLetterError = coverLetterResponseData.includes(
        "TimeOut Error: Try Again"
      );
      isCoverLetterError = coverLetterResponseData.includes("Sure,");
      if (isCoverLetterError) {
        setnodata(true);
        setloading(false);
        setoutput(false);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVCoverLetter(coverLetterResponseData);
    } catch (err) {
      console.log(err.response);
      setnodata(false);
      setloading(false);
      setoutput(false);
    } finally {
      Mixpanel.track("YsfJobApp:Generate CV Feedback");
    }
  };

  const populateCVFeedback = async (cvFeedbackResponse) => {
    try {
      //console.log("cvFeedbackResponse:"+cvFeedbackResponse);
      var splitFeedbackData;
      var feedbackTxt;
      let feedbackTempArray = [];
      splitFeedbackData = cvFeedbackResponse.split("\n\n");
      if (splitFeedbackData.length > 0) {
        splitFeedbackData = cvFeedbackResponse.split("\n\n");
        splitFeedbackData.splice(0, 2);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace(/\d+/g, "");
              feedbackTxt = feedbackTxt.replace(/\./g, "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              if (feedbackTxt.length > 80) {
                var feedbackObj = { id: index, text: _feedbackTxt.trim() };
                feedbackTempArray.push(feedbackObj);
              }
            }
          });
        setlist(feedbackTempArray);
      } else if (cvFeedbackResponse.split(srNoregex).length > 0) {
        splitFeedbackData = cvFeedbackResponse.split(srNoregex);
        splitFeedbackData.splice(0, 1);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              var feedbackObj = { id: index, text: _feedbackTxt.trim() };
              feedbackTempArray.push(feedbackObj);
            }
          });
        setlist(feedbackTempArray);
      } else if (cvFeedbackResponse.split(splitText).length > 0) {
        splitFeedbackData = cvFeedbackResponse.split(splitText);
        splitFeedbackData.splice(0, 1);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              var feedbackObj = { id: index, text: _feedbackTxt.trim() };
              feedbackTempArray.push(feedbackObj);
            }
          });
        setlist(feedbackTempArray);
        setIsShowSaveFinishBtn(false);
      }

      //console.log("feedbackTempArray_length#"+feedbackTempArray.length);
      if (feedbackTempArray.length === 0) {
        setGetError(true);
        setIsShowGenerateBtn(true);
      } else {
        setloading(false);
        setoutput(true);
        setIsShowSaveFinishBtn(false);
      }
    } catch (e) {
      console.log("feedback-error#");
    }
  };

  const populateCVCoverLetter = async (cvCoverLetterResponse) => {
    try {
      console.log("coverLetterData#" + cvCoverLetterResponse);
      let introTxt;
      let bodyTxt;
      let conclusionTxt;
      var isCoverLetterError = cvCoverLetterResponse.includes(
        "TimeOut Error: Try Again"
      );
      isCoverLetterError = cvCoverLetterResponse.includes("Sure,");
      if (isCoverLetterError) {
        setnodata(true);
        setloading(false);
        setoutput(false);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      var splitSections = splitMultipleLangText(cvCoverLetterResponse);
      if (splitSections) {
        //console.log(splitSections);
        introTxt = splitSections["Introduction"];
        bodyTxt = splitSections["Body"];
        conclusionTxt = splitSections["Conclusion"];
        setintrotext(removeColon(introTxt.trim()));
        setbodytext(removeColon(bodyTxt.trim()));
        setConclusionTxt(removeColon(conclusionTxt.trim()));
        setloading(false);
        setoutput(true);
        setenablegenateBtn(false);
        setIsCoverletterloading(false);
        setIsShowSaveFinishBtn(false);
      }
    } catch (e) {
      console.log("Error in Feedback Generation");
      setIsShowSaveFinishBtn(true);
    }
  };

  function splitMultipleLangText(text) {
    const sections = {};
    const parts = text.split(/(Introduction|Body|Conclusion)/);

    for (let i = 1; i < parts.length; i += 2) {
      const heading = parts[i].trim();
      const content = parts[i + 1].trim();
      sections[heading] = content;
    }
    return sections;
  }

  const BtnGoToConcernHnd = async () => {
    try {
      console.log("fetchReducer?.stepcount" + fetchReducer?.stepcount);
      console.log("jobTitleAction#" + fetchReducer?.title);
      console.log("jobpostIdAction#" + fetchReducer?.jobpostId);
      console.log("jobPostUrl#" + fetchReducer?.url);
      setJobTitle(fetchReducer?.title);
      setJobPostUrl(fetchReducer?.url);
      setJobPostId(fetchReducer?.jobpostId);
      dispatch(JobTitleAction(jobTitle));
      dispatch(JobpostUrlAction(jobPostUrl));
      dispatch(JobPostIdAction(jobPostId));
      ReUploadCVHnd();
      navigate("/concern", {
        state: {
          id: fetchReducer?.jobpostId,
          CompanyJobTitle: fetchReducer?.title,
        },
      });
    } catch (error) {
      console.log("Error in Good To Go!");
    } finally {
      Mixpanel.track("YsfJobApp:Go To Concern");
    }
  };

  const ReUploadCVHnd = async () => {
    let updateCV_Obj = {
      UserEmail: user_email,
      JobPostId: fetchReducer?.jobpostId,
      JobPostUserCVName: fileName,
      JobPostUserCV: cvTxt,
    };
    console.log("updateCV_Obj#" + updateCV_Obj);
    await ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetReUploadCVApi",
      updateCV_Obj
    );
  };

  const CancelUploadHandle = async () => {
    setuploadLoader(false);
    setisfile("");
  };
  const ToCoverLetter = async () => {
    setactiveTab("cover");
    Mixpanel.track("YsfJobApp:To Cover Letter");
  };

  const removeColon = (strTxt) => {
    return strTxt.replace(":", "").trim();
  };
  const removeAsterisks = (strTxt) => {
    return strTxt.replace(/\*/g, "");
  };
  const removeHashBeforeTxt = (strTxt) => {
    return strTxt.replace(/(^#*| #+)/g, "");
  };
  const removeHashesFromNewLines = (str) => {
    return str.replace(/(^|\n)###/g, "");
  };
  // END
  return (
    <>
      <div className="row now-gutters vh-class">
        <div className="col-lg-6">
          <div className="common-left">
            <div className="pageHeading">
              <h1>
                <img src={IMAGE.Document} alt="" />
                {feedback ||
                fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                fetchReducer.goToConceren === "CVFeedbackGenerated"
                  ? "My Profile"
                  : "Interview"}
              </h1>
            </div>
            <div className="default-card">
              <div className="default-card-header">
                <span>1</span>
                <div className="default-card-header-text">
                  <h3>
                    {" "}
                    {feedback ||
                    fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                    fetchReducer.goToConceren === "CVFeedbackGenerated"
                      ? "Analyse and finalise your CV!"
                      : "Prepare for your interview"}
                  </h3>
                  <p>
                    {feedback ||
                    fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                    fetchReducer.goToConceren === "CVFeedbackGenerated"
                      ? "Let’s start by uploading your CV or pasting your LinkedIn profile."
                      : "Start the session by uploading CV or pasting your linkedin profile."}
                  </p>
                </div>
                <div className="default-card-header-img">
                  <img src={IMAGE.context_card_img} alt="" />
                </div>
              </div>
              <div className="default-card-body">
                <div
                  className="input-field-list"
                  style={{
                    opacity: isactivecvfield ? 1 : 0.4,
                    pointerEvents: isactivecvfield ? "auto" : "none",
                  }}
                >
                  <div className="input-field-label">
                    <span>
                      <img src={IMAGE.qa_icon} alt="" />
                    </span>
                    <div className="input-field-info">
                      <h4>Click to upload your CV</h4>
                      <p>You can also drop your file in this area.</p>
                    </div>
                  </div>
                  <div class="input-field">
                    {isfile === "" ? (
                      <FileUploader
                        classes="custom-drop"
                        children={
                          <div className="fileupload-option">
                            <span>
                              <img src={IMAGE.upload_icon} alt="" />
                            </span>
                            <p>Click or drop the file upload it</p>
                          </div>
                        }
                        handleChange={CVUploadHandleChange}
                        onDrop={(file) => setisfile(file)}
                        onSelect={(file) => setisfile(file)}
                        name="file"
                        types={fileTypes}
                      />
                    ) : (
                      <>
                        <div className="cv-output">
                          {uploadLoader ? (
                            <div className="uploadLoader">
                              <h4>
                                <img
                                  src={IMAGE.loader_icon}
                                  className="LoaderIcon"
                                  alt=""
                                />{" "}
                                {file.name}
                              </h4>
                              <button onClick={CancelUploadHandle}>
                                <img src={IMAGE.Upload_cross} alt="" />
                              </button>
                            </div>
                          ) : (
                            <>
                              <div className="success-upload">
                                <h4>
                                  <img src={IMAGE.Attachment} alt="" />{" "}
                                  {fileName}
                                </h4>
                                <div>
                                  <button
                                    className="replaceCVBtn"
                                    onClick={DeleteHandle}
                                  >
                                    <img src={IMAGE.Refresh_icon} alt="" />{" "}
                                    Replace CV
                                  </button>
                                  <button
                                    onClick={DeleteHandle}
                                    className="DeleteBrn"
                                  >
                                    <img src={IMAGE.Delete} alt="" />
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="or-sep">
                  <span>or</span>
                </div>
                <div
                  className="input-field-list"
                  style={{
                    opacity: isactiveurlfield ? 1 : 0.4,
                    pointerEvents: isfile === "" ? "auto" : "none",
                  }}
                >
                  <div className="input-field-label">
                    <span>
                      <img src={IMAGE.qa_icon} alt="" />
                    </span>
                    <div className="input-field-info">
                      <h4>Insert your LinkedIn URL</h4>
                      <p>
                        You can paste your audience’s LinkedIn URL directly in
                        here:
                      </p>
                    </div>
                  </div>

                  <div class="input-field">
                    <div class="linkdinUrl">
                      <input
                        type="url"
                        class="form-control"
                        placeholder="Insert your LinkedIn URL here"
                        value={linkdinUrl}
                        onChange={(e) => linkdinUrlHandleInput(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {goToConcern === 1 && (
                  <div className="text-end">
                    {linkedinError && (
                      <>
                        <h5 className="wrongposturltext">
                          Please enter a valid Linkedin URL!{" "}
                        </h5>
                        <br></br>
                      </>
                    )}
                    {isShowGenerateBtn && (
                      <button
                        className="themeBtn hover-btn"
                        onClick={() => BtnGenerateFeedbackHnd(false)}
                        disabled={loading ? true : false}
                      >
                        Generate Feedback
                      </button>
                    )}
                  </div>
                )}
                {getError && (
                  <div className="noresultFound">
                    <img src={IMAGE.alert_icon} alt="" />
                    <div className="noresultFoundInner">
                      <h4>Results not generated</h4>
                      <p>
                        Oops!!! <br />
                        Looks like our servers are having a busy day.
                        <br /> Please try to generate your results again.
                      </p>
                    </div>
                    <button
                      className="tryBtn"
                      onClick={() => BtnGenerateFeedbackHnd(true)}
                      disabled={loading ? true : false}
                    >
                      Try Again
                    </button>
                  </div>
                )}

                {goToConcern === 2 && (
                  <div className="text-end">
                    {isShowGenerateBtn && (
                      <button
                        className="themeBtn hover-btn"
                        onClick={BtnGoToConcernHnd}
                        disabled={loading ? true : false}
                      >
                        Generate Concerns & Gains
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0">
          <div className={output ? "comonn-right output" : "comonn-right"}>
            {loading && <ConcernLoader />}
            {output && (
              <div className="output-view mt-5 job-profile">
                <ul className="concern-container-ouput-tabs">
                  <li>
                    <button
                      className={
                        activeTab === "cv" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setactiveTab("cv")}
                    >
                      <img src={IMAGE.Supergroup} alt="" /> CV Feedback
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        activeTab === "cover" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setactiveTab("cover")}
                    >
                      <img src={IMAGE.Message_Align_Right} alt="" /> Cover
                      letter
                    </button>
                  </li>
                </ul>
                <div>
                  {activeTab === "cv" ? (
                    <>
                      <div class="output-view-body p-0 step-oneview">
                        <h4 className="special_heading">
                          {" "}
                          <img src={IMAGE.notes_checkicon} alt="" /> Please
                          review the suggestions, update your CV, and re-submit
                          it to see how much better it aligns!
                        </h4>
                        <ul id="ListedBox">
                          {list &&
                            list.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    item.toggleSelected ? "selected" : ""
                                  }
                                >
                                  {/* <label onClick={() => SelectHandle(item)}> {item.text}</label>    */}
                                  <span onClick={() => SelectHandle(item)}>
                                    {item.toggleSelected ? (
                                      <img src={IMAGE.check_icon_one} alt="" />
                                    ) : (
                                      <img src={IMAGE.uncheck_icon} alt="" />
                                    )}
                                  </span>
                                  <label> {item.text}</label>
                                  <button className="crosBtn">
                                    <img
                                      src={IMAGE.x_button}
                                      alt=""
                                      onClick={() => ModalShow(item.id)}
                                    />
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="text-end mt-3">
                        {/* <button className="coverlettrBtn hover-btn" onClick={ToCoverLetter}>To Cover Letter</button> */}
                        <button
                          className="coverlettrBtn hover-btn"
                          onClick={ToCoverLetter}
                        >
                          To Cover Letter
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cover-letter">
                        <h4 className="special_heading">
                          {" "}
                          <img src={IMAGE.edit_note} alt="" /> Get a head start
                          with this personalized cover letter. Feel free to
                          paste anywhere you want!
                        </h4>
                        <div className="cover-letter-sep">
                          <h4>
                            Introduction{" "}
                            <button
                              onClick={() => copy(introInitialTxt + introtext)}
                            >
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                          </h4>
                          <p> {introInitialTxt} </p>
                          <p>{introtext}</p>
                        </div>
                        <div className="cover-letter-sep">
                          <h4>
                            Body Content{" "}
                            <button onClick={() => copy(bodytext)}>
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                          </h4>

                          {isCoverletterloading && (
                            <Loader title="Job cover letter!!" />
                          )}
                          {!isCoverletterloading && <pre>{bodytext}</pre>}
                        </div>
                        <div className="cover-letter-sep">
                          <h4>
                            Conclusion{" "}
                            <button onClick={() => copy(conclusionTxt)}>
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                          </h4>
                          {isCoverletterloading && (
                            <Loader title="Job cover letter!!" />
                          )}
                          {!isCoverletterloading && <pre>{conclusionTxt}</pre>}
                        </div>
                      </div>
                      <div className="go_btn w-100">
                        {!isShowSaveFinishBtn && (
                          <button
                            onClick={() => NextAction(1)}
                            className="themeBtn  hover-btn"
                          >
                            Finish
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {nodata && (
              <Nodata
                image={IMAGE.YSF_generate_icon}
                text="This is where you find your CV feedback and improvement suggestions "
              />
            )}
          </div>
        </div>
      </div>
      <div className="concern-footer">
        <NavLink to="/home">
          <i className="fa-solid fa-arrow-left"></i> My job list
        </NavLink>
      </div>
      <Modal
        show={showmodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="deletemodal p-5">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              <img src={IMAGE.del_img} alt="" />
            </div>
            <div className="col-lg-6 text-center">
              <h3>Are you sure?</h3>
              <h6>
                You’re removing the suggestion. <br></br> This can't be
                recovered.
              </h6>
              <ul>
                <li>
                  <button
                    className="borderBtn"
                    onClick={() => setshowmodal(false)}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button className="solidBtn" onClick={() => DlistHandle()}>
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobProfile;
