import React from "react";
import { IMAGE } from "../common/Theme";
import { useNavigate, useLocation } from "react-router-dom";

const FinalunsubscriptionConfirmation = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { cancelPeriodEnd } = location.state || {};
  const GoToHome = (step) => {
    localStorage.removeItem("subscriptionId");
    localStorage.removeItem("DaysLeft");
    navigate("/home");
    window.location.reload();
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Format the date using toLocaleDateString
    return date.toLocaleDateString("en-US", {
      day: "2-digit", // 'numeric' for single digit days
      month: "long", // Use 'short' for abbreviated month (e.g., "Sep")
      year: "numeric",
    });
  }
  return (
    <div className="common-panel home-page success-box">
      <div className="home-container">
        <div className="subscription-container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={IMAGE.Gentleman_img} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 text-left">
              <h3>Unsubscribed!</h3>
              <p>
                You’re now unsubscribed. You’ll still have access to premium
                features until {formatDate(cancelPeriodEnd)}. Feel free to
                re-subscribe anytime you'd like.
              </p>
              <ul className="success-box_btn">
                <li>
                  <button className="BtnStyle_two" onClick={() => GoToHome()}>
                    Close
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalunsubscriptionConfirmation;
