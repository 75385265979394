import React from "react";
import { NavLink } from "react-router-dom";
import { IMAGE } from "./Theme";

const Sidebar = () => {
  return (
    <div className="left-sidebar">
      <ul>
        <li>
          <NavLink
            to="/Subscription"
            className={({ isActive, isPending }) => (isActive ? "active" : "")}
          >
            <img src={IMAGE.subscription_icon} alt="icon" /> My Subscription
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/Billing"
            className={({ isActive, isPending }) => (isActive ? "active" : "")}
          >
            <img src={IMAGE.billing_icon} alt="icon" /> Billing
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Refer"
            className={({ isActive, isPending }) => (isActive ? "active" : "")}
          >
            <img src={IMAGE.Gift} alt="icon" /> Refer friends
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Redeem"
            className={({ isActive, isPending }) => (isActive ? "active" : "")}
          >
            <img src={IMAGE.reedem_icon_one} alt="icon" /> Redeem
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
