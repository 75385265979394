import React, { useState } from "react";
import ApiConnection from "../common/ApiConnection";
import { IMAGE } from "../common/Theme";
import { useNavigate } from "react-router-dom";

const UnsubscriptionConfirmation = () => {
  const [cancelmsg, setCancelmsg] = useState(null);
  const [reasonList, setReasonList] = useState("I’ve got my dream job!");
  var user_email = localStorage.getItem("ysf_email");
  var subscriptionId = localStorage.getItem("subscriptionId");
  const [cancelReason, setCancelReason] = useState("");
  const [cancellationDate, setCancellationDate] = useState("");
  let navigate = useNavigate();

  const CancelSubscription = async () => {
    try {
      let subCancelMsg = reasonList + " " + cancelReason;
      let cancelSubscriptionObj = {
        UserEmail: user_email,
        SubscriptionId: subscriptionId,
        SubscriptionCancelMsg: subCancelMsg,
      };
      var response = await ApiConnection.post(
        "/api/JobbsprangetPayment/cancel-subscription",
        cancelSubscriptionObj
      );
      if (response.data.isSuccess) {
        console.log(response.data.subscriptionCancelMsg);
        setCancelmsg(response.data.subscriptionCancelMsg);
        setCancellationDate(response.data.cancelPeriodEnd);
        console.log("cancelPeriodEnd#" + response.data.cancelPeriodEnd);
        navigate("/Cancelconfirm", {
          state: { cancelPeriodEnd: response.data.cancelPeriodEnd },
        });
      } else {
        setCancelmsg(response.data.subscriptionCancelMsg);
        console.log(response.data.subscriptionCancelMsg);
      }
    } catch (e) {
      console.log("cancelSubscription");
    }
  };

  return (
    <div className="common-panel home-page success-box before-go">
      <div className="home-container">
        <div className="subscription-container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={IMAGE.Gentleman_img} alt="" className="w-100" />
            </div>
            <div className="col-lg-5 text-center">
              <h3>Before you go!</h3>
              <p>
                Thanks for being with us. Goodbye is always hard. <br></br>{" "}
                Please help us know why you leave.
              </p>

              <div className="radio-group-list">
                <ul>
                  <li
                    className={
                      reasonList === "I’ve got my dream job!" ? "active" : null
                    }
                    onClick={() => setReasonList("I’ve got my dream job!")}
                  >
                    <span></span>
                    <label>I’ve got my dream job!</label>
                  </li>
                  <li
                    className={
                      reasonList === "I’m not interested anymore"
                        ? "active"
                        : null
                    }
                    onClick={() => setReasonList("I’m not interested anymore")}
                  >
                    <span></span>
                    <label>I’m not interested anymore.</label>
                  </li>
                  <li
                    className={
                      reasonList === "I’ve got another plan" ? "active" : null
                    }
                    onClick={() => setReasonList("I’ve got another plan")}
                  >
                    <span></span>
                    <label>I’ve got another plan.</label>
                  </li>
                  <li
                    className={reasonList === "Other reason" ? "active" : null}
                    onClick={() => setReasonList("Other reason")}
                  >
                    <span></span>
                    <label>Other reason.</label>
                  </li>
                </ul>
              </div>
              <textarea
                rows={2}
                maxLength={250}
                onChange={(e) => setCancelReason(e.target.value)}
                className="form-control mt-2"
              ></textarea>
              <ul className="success-box_btn mt-3">
                <li>
                  <button className="BtnStyle_two" onClick={CancelSubscription}>
                    Send
                  </button>
                </li>
              </ul>
              <br />
              <span>{cancelmsg} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscriptionConfirmation;
