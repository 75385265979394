import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IMAGE } from "../common/Theme";
import { useGoogleLogin } from "@react-oauth/google";
import ApiConnection from "../common/ApiConnection";
import { Mixpanel } from "../auth/YsfMixPanel";

const Signup = () => {
  let navigate = useNavigate();
  const [isCheck, setIsCheck] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [referCoupon, SetReferCoupon] = useState("");
  const [validCodeMsg, setValidCodeMsg] = useState("");
  const referralCouponRef = useRef(null);
  const [validCode, setValidCode] = useState(false);
  const [isValidCodeMsg, setIsValidCodeMsg] = useState("");

  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (sessionStorage.getItem("JobApp_ReferId")) {
      let _referCoupon = sessionStorage.getItem("JobApp_ReferId");
      SetReferCoupon(_referCoupon.toString());
      setIsCheck(true);
      const timeoutId = setTimeout(async () => {
        await ApplyCoupon(_referCoupon);
      }, 100); // 2000 milliseconds = 2 seconds
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const LoginHandle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      if (codeResponse.access_token) {
        let url_ =
          "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
          codeResponse.access_token;
        fetch(url_)
          .then((res) => res.json())
          .then((responseData) => {
            if (responseData.email_verified) {
              Mixpanel.identify(responseData.email);
              Mixpanel.people.set({
                $first_name: responseData.name,
                $email: responseData.email,
              });
              Mixpanel.track("YsfJobApp:Successful login");

              const UserSignup = async () => {
                try {
                  let body = {
                    UserName: responseData.name,
                    UserEmail: responseData.email,
                  };
                  const response = await ApiConnection.post(
                    "/JobbsprangetUser/RegisterUser",
                    body
                  );
                  if (
                    response !== "" ||
                    response !== "TimeOut Error: Try Again"
                  ) {
                    if (response.data?.isOnboardingSuccess) {
                      localStorage.setItem("ysf_name", responseData.name);
                      localStorage.setItem("ysf_email", responseData.email);
                      localStorage.setItem(
                        "DaysLeft",
                        response.data.licenseLeftdays
                      );
                      localStorage.setItem("license", response.data.license);
                      localStorage.setItem(
                        "subscriptionId",
                        response.data.subscriptionId
                      );
                      localStorage.setItem(
                        "JobApp_UserId",
                        response.data.userId
                      );
                      localStorage.setItem(
                        "JobApp_SubscriptionType",
                        response.data.subscriptionType
                      );

                      let user_name = responseData.given_name;
                      if (user_name) {
                        user_name =
                          user_name.slice(0, 1).toUpperCase() +
                          user_name.slice(1, user_name.length);
                        localStorage.setItem("fname", user_name);
                      } else {
                        localStorage.setItem("fname", responseData.given_name);
                      }
                      window.location.reload();
                    } else {
                      navigate("/onboard", {
                        state: {
                          user_name: responseData.name,
                          user_email: responseData.email,
                          first_name: responseData.given_name,
                          license: response.data.license,
                          DaysLeft: response.data.licenseLeftdays,
                          subscriptionId: response.data.subscriptionId,
                          referralCoupon: validCode,
                        },
                      });
                    }
                  }
                } catch (e) {
                  console.log("Error! in Sign Up!");
                }
              };
              UserSignup();
            }
          });
      }
    },
    onError: (codeResponse) => {
      console.log(codeResponse);
      console.log("Fail");
    },
  });

  const ReferralCodeHandler = () => {
    ApplyCoupon();
  };
  const SetCouponVal = async (couponCode) => {
    if (couponCode.length > 0) {
      SetReferCoupon(couponCode);
      if (isValidCode && referCoupon.trim() !== couponCode.trim()) {
        setIsValidCode(false);
        setIsValidCodeMsg(false);
      }
    } else {
      SetReferCoupon("");
      setIsValidCode(false);
      setIsValidCodeMsg(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") ApplyCoupon();
  };
  const ApplyCoupon = async (_referCoupon) => {
    try {
      let getReferralCoupon;
      if (_referCoupon) {
        getReferralCoupon = _referCoupon;
        setValidCode(_referCoupon);
      }
      if (referCoupon) getReferralCoupon = referCoupon;
      if (!getReferralCoupon) {
        setIsValidCode(false);
        setValidCodeMsg("Please enter Referral code!!");
        referralCouponRef.current.focus();
        return;
      }

      let validateCoupon = {
        Couponcode: getReferralCoupon.toUpperCase(),
        CouponPlan: "2",
      };

      const response = await ApiConnection.post(
        "/JobbsprangetCoupon/CouponValidation",
        validateCoupon
      );
      if (response.data.isSuccess) {
        setValidCode(getReferralCoupon.toUpperCase());
        sessionStorage.setItem(
          "JobApp_ReferId",
          getReferralCoupon.toUpperCase()
        );
        setIsValidCode(true);
        Mixpanel.track("YsfJobApp:ReferralCoupon:" + getReferralCoupon);
        if (response.data.couponDuration >= 30) {
          setValidCodeMsg(response.data.couponDurationTxt);
        } else {
          setValidCodeMsg(
            "Your code is valid. Sign in now to get 1 month free!"
          );
        }

        setIsValidCodeMsg(true);
      } else {
        sessionStorage.removeItem("JobApp_ReferId");
        setIsValidCode(false);
        setIsValidCodeMsg(true);
        setValidCodeMsg("Your code is invalid!!");
        referralCouponRef.current.focus();
      }
    } catch (err) {
      console.log("Error in ApplyCoupon!!");
    }
  };
  const CheckCoupon = (event) => {
    if (event) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  };
  const ReferralCouponHandle = (value) => {
    if (value !== "") {
      SetCouponVal(value);
    } else {
      SetCouponVal("");
      sessionStorage.removeItem("JobApp_ReferId");
    }
  };

  return (
    <div className="login-page p-0">
      <div className="signup-container">
        <div className="row no-gutters h-100">
          <div className="col-lg-8 col-sm-7">
            <div className="signup-container-lft h-100">
              <Slider {...settings} className="h-100">
                <div className="slider-animate">
                  <h3>
                    Make your CV stand <br></br> out with{" "}
                    <span>AI analysis</span>
                  </h3>
                  <img src={IMAGE.slider_one} alt="" />
                </div>
                <div className="slider-animate">
                  <h3>
                    Elevate your interview <br></br> preparation with AI{" "}
                    <br></br> and <span>Neuroscience</span>
                  </h3>
                  <img src={IMAGE.slider_two} alt="" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-4 col-sm-5">
            <div className="signup-container-rht">
              <div className="signup-container-rht-inner">
                <h3>Create an Account</h3>

                <div className="refer_code">
                  <button
                    className="checkboxBtn"
                    onClick={() => CheckCoupon(!isCheck)}
                  >
                    <img
                      src={
                        isCheck
                          ? IMAGE.checkbox_select
                          : IMAGE.checkbox_unselect
                      }
                      alt="checkboxicon"
                    />
                    I have a referral code
                  </button>
                  {isCheck && (
                    <div className="refer-input">
                      <input
                        type="text"
                        ref={referralCouponRef}
                        placeholder="Referral Code"
                        maxLength="10"
                        value={referCoupon}
                        onChange={(e) =>
                          ReferralCouponHandle(e.target.value.toUpperCase())
                        }
                        onKeyDown={handleKeyDown}
                      />
                      {isValidCode ? (
                        <img
                          src={IMAGE.confirm_check}
                          alt="arrow_right"
                          className="confirm_check"
                        />
                      ) : (
                        <button onClick={ReferralCodeHandler}>
                          <img src={IMAGE.arrow_right} alt="arrow_right" />
                        </button>
                      )}
                    </div>
                  )}

                  {isValidCodeMsg && <h5>{validCodeMsg}</h5>}
                </div>

                <button onClick={LoginHandle} className="hover-btn google_btn">
                  <img src={IMAGE.google_icon} alt="" />
                  Log in with Google
                </button>
              </div>
              <h6>
                By continuing, you agree to our{" "}
                <a
                  href="https://www.yourspeechfactory.com/eula"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.yourspeechfactory.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;
