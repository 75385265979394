import React, { useEffect, useState, useRef } from "react";
import { IMAGE } from "../../common/Theme";
import Modal from "react-bootstrap/Modal";
import ApiConnection from "../../common/ApiConnection";
import copy from "copy-to-clipboard";
import Sidebar from "../../common/Sidebar";

const Referral = () => {
  const [referralLink, setReferralLink] = useState("");
  const [refermodal, setrefermodal] = useState(false);
  const [referUserName, setReferUserName] = useState("");
  const [referUserEmail, setReferUserEmail] = useState("");
  const [referlist, setReferlist] = useState([]);
  const [sendMsg, setSendMsg] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isCopyCode, setIsCopyCode] = useState(false);
  const [selectedValue, setSelectedValue] = useState("option1");

  const isProd = process.env.REACT_APP_IS_PROD;
  var user_email = localStorage.getItem("ysf_email");
  let jobApp_UserId = localStorage.getItem("JobApp_UserId");

  const refrralNameInput = useRef(null);
  const refrralEmailInput = useRef(null);
  const popupWindowRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);

  useEffect(() => {
    if (isProd === "Y")
      setReferralLink("https://app.jobsway.ai/" + jobApp_UserId);
    else {
      setReferralLink("https://jobswayqa.azurewebsites.net/" + jobApp_UserId);
    }
  }, [isProd, user_email, jobApp_UserId]);

  const btnReferHnd = async () => {
    if (isProd === "Y")
      setReferralLink("https://app.jobsway.ai/" + jobApp_UserId);
    else
      setReferralLink("https://jobswayqa.azurewebsites.net/" + jobApp_UserId);

    if (!referUserName.trim()) {
      setSendMsg("Please enter freiend's name!!");
      setIsSend(false);
      refrralNameInput.current.focus();
      return;
    }
    if (!referUserEmail.trim()) {
      setSendMsg("Please enter freiend's email!!");
      setIsSend(false);
      refrralEmailInput.current.focus();
      return;
    }
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(referUserEmail.trim())) {
      setSendMsg("Please enter valid Email!!");
      setIsSend(false);
      refrralEmailInput.current.focus();
      return;
    }
    if (
      referUserEmail.trim().toLowerCase().includes(user_email.toLowerCase())
    ) {
      setSendMsg("You can not refer self. Please enter friend's Email!!");
      setIsSend(false);
      refrralEmailInput.current.focus();
      return;
    }
    if (
      referlist.some(
        (refer) =>
          refer.ReferUserEmail.toLowerCase() ===
          referUserEmail.trim().toLowerCase()
      )
    ) {
      setSendMsg("Sorry, You already referred to you friend!!!");
      setIsSend(false);
      refrralEmailInput.current.focus();
      return;
    }

    let refName =
      referUserName.slice(0, 1).toUpperCase() +
      referUserName.slice(1, referUserName.length);
    console.log("refName" + refName);
    setReferUserName(refName);
    let refrralUserObj = {
      UserEmail: user_email,
      ReferUserName: referUserName.trim(),
      ReferUserEmail: referUserEmail.trim(),
      ReferLink: referralLink,
    };
    var response = await ApiConnection.post(
      "/JobbsprangetEmail/ReferralEmail",
      refrralUserObj
    );
    if (response.data?.isSuccess) {
      setSendMsg(response.data.emailSendMessage);
      setIsSend(true);
    } else {
      setSendMsg(response.data.emailSendMessage);
      setIsSend(false);
    }
  };

  const referralName = (value) => {
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setReferUserName(capitalizedValue);
  };
  const referralEmail = (value) => {
    setReferUserEmail(value);
  };
  const copyReferLink = () => {
    copy(referralLink);
    setIsCopy(true);
  };
  const copyCode = () => {
    copy(jobApp_UserId);
    setIsCopyCode(true);
  };

  const ReferralModel = () => {
    if (refrralNameInput.current) {
      refrralNameInput.current?.focus();
    }
    setSendMsg("");
    setIsSend(true);
    setrefermodal(false);
    setReferUserName("");
    setReferUserEmail("");
    setIsCopy(false);
    setSelectedValue("option1");
  };
  const OpenReferralModel = () => {
    setrefermodal(true);
    setSelectedValue("option1");
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const handleWhatsApp = () => {
    const message =
      `Hi! I use Jobsway.ai for CV feedback and cover letter assistance. It’s super easy and packed with great features! Try it out with a free one-month subscription link! ` +
      referralLink +
      " or one-month free coupon code: " +
      jobApp_UserId;
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;

    const windowName = "Your Speech Factory"; // Name of the new window
    const width = 680;
    const height = 550;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;
    const newWindow = window.open(whatsappLink, windowName, windowFeatures);

    // Check if the window is successfully opened and then set the title
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.document.title = windowName; // Set the title
      };
    }

    popupWindowRef.current = newWindow;
    // Start checking if the window is closed
    popupCheckIntervalRef.current = setInterval(() => {
      if (popupWindowRef.current && popupWindowRef.current.closed) {
        clearInterval(popupCheckIntervalRef.current);
        setSelectedValue("option1");
      }
    }, 500); // Check every 500ms
  };
  useEffect(() => {
    getReferList();
  }, []);

  const getReferList = async () => {
    try {
      let getReferListObj = { UserEmail: user_email };
      const response = await ApiConnection.post(
        "/JobbsprangetEmail/GetReferralEmail",
        getReferListObj
      );
      if (response.data?.isSuccess) {
        var ReferArray = [];
        var referList = response.data?.referUserList;
        referList?.forEach((element) => {
          ReferArray.push({
            ReferUserName: element.referUserName,
            ReferUserEmail: element.referUserEmail,
            ReferStatus: element.referStatus,
            ReferLogDate: element.referLogDate,
          });
        });
        setReferlist(ReferArray);
      }
    } catch (error) {
      console.log("Error in loading Refer list!!!");
    }
  };

  useEffect(() => {
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="common-panel home-page">
      <div className="wallet-container">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-xxl-2">
            <Sidebar />
          </div>
          <div className="col-lg-9 col-xl-9 col-xxl-10">
            <div className="refer-container">
              <h3>Get 30 days free!</h3>
              <p>
                Share your referral links. When someone sign up, both of you get
                30 days free.
              </p>
              <div className="row">
                <div className="col-lg-8">
                  <h4>Copy referral link</h4>
                  <div className="refer-url">
                    <input
                      type="text"
                      className="form-control"
                      value={referralLink}
                    />
                    <div className="refer-url-right">
                      <button onClick={copyReferLink}>
                        <img src={IMAGE.content_copy} alt="icon" /> Copy
                      </button>
                      {isCopy && (
                        <span style={{ color: "green" }}>
                          Referral link copied to clipboard!
                        </span>
                      )}
                    </div>
                  </div>

                  <h4>Copy referral code</h4>
                  <div className="code-ref-view">
                    <label>{jobApp_UserId}</label>
                    <button onClick={copyCode}>
                      <img src={IMAGE.content_copy} alt="icon" /> Copy Code
                    </button>
                    {isCopyCode && (
                      <span style={{ color: "green" }}>
                        Referral code copied to clipboard!
                      </span>
                    )}
                  </div>

                  <h4>Invite now using</h4>
                  <div className="invite-btn">
                    <button>
                      <img
                        src={IMAGE.email_icon}
                        alt="icon"
                        onClick={OpenReferralModel}
                      />
                    </button>
                    <button>
                      <img
                        src={IMAGE.whatsapp_icon}
                        alt="icon"
                        onClick={handleWhatsApp}
                      />
                    </button>
                  </div>
                </div>
                <div className="col-lg-4">
                  <h5>LIMITLESS REWARDS</h5>
                  <div className="ref-right">
                    <h6>You get 30 days for every person who sign up!</h6>
                    <img
                      src={IMAGE.undraw_winners}
                      alt="icon"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={refermodal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="logout-modal text-center p-5">
          <h4>Refer a friend </h4>
          <hr />
          <div className="form-group mb-2 mt-2">
            <input
              type="radio"
              id="option1"
              value="option1"
              checked={selectedValue === "option1"}
              onChange={() => handleRadioChange("option1")}
            />
            &nbsp;&nbsp;
            <label htmlFor="option1">Email</label>&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          {selectedValue === "option1" && (
            <>
              <div className="form-group mb-3 mt-4">
                <input
                  type="text"
                  ref={refrralNameInput}
                  autoFocus
                  className="form-control"
                  placeholder="Friend's name"
                  value={referUserName}
                  onChange={(e) => referralName(e.target.value)}
                />
              </div>
              <div className="form-group mb-5">
                <input
                  type="text"
                  ref={refrralEmailInput}
                  className="form-control"
                  placeholder="Friend's email"
                  value={referUserEmail}
                  onChange={(e) => referralEmail(e.target.value)}
                />
              </div>
              <button className="btn  btn-primary px-4" onClick={btnReferHnd}>
                Send
              </button>
              <div className="form-group mb-6">
                <span style={{ color: isSend === true ? "green" : "red" }}>
                  {sendMsg}{" "}
                </span>
              </div>
            </>
          )}

          <button className="close_Icon" onClick={ReferralModel}>
            <img src={IMAGE.close_icon} alt="" />
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Referral;
