import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../common/Sidebar";
import ApiConnection from "../common/ApiConnection";
import { IMAGE } from "../common/Theme";
import { Mixpanel } from "../auth/YsfMixPanel";

const Redeem = () => {
  const stripeAll = useStripe();
  var navigate = useNavigate();
  var user_email = localStorage.getItem("ysf_email");
  var subscriptionType = localStorage.getItem("JobApp_SubscriptionType");

  const [referlist, setReferlist] = useState([]);
  const [isValidCode, setIsValidCode] = useState(false);
  const [referCoupon, SetReferCoupon] = useState("");
  const [validCodeMsg, setValidCodeMsg] = useState("");
  const referralCouponRef = useRef(null);
  const [isValidCodeMsg, setIsValidCodeMsg] = useState("");
  const isProd = process.env.REACT_APP_IS_PROD;
  const [unlockModalShow, setUnlockModalShow] = useState(false);
  const [aciveUserUseReferralCode, setAciveUserUseReferralCode] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  useEffect(() => {
    getReferList();
  }, []);

  const ReferralCodeHandler = () => {
    ApplyCoupon();
  };

  const SetCouponVal = async (couponCode) => {
    console.log("couponCode" + couponCode);
    if (couponCode.length > 0) {
      SetReferCoupon(couponCode);
      if (isValidCode && referCoupon.trim() !== couponCode.trim()) {
        setIsValidCode(false);
        setIsValidCodeMsg(false);
      }
    } else {
      SetReferCoupon("");
      setIsValidCode(false);
      setIsValidCodeMsg(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") ApplyCoupon();
  };

  const ApplyCoupon = async () => {
    try {
      if (!referCoupon) {
        setIsValidCode(false);
        setValidCodeMsg("Please enter Referral code!!");
        referralCouponRef.current.focus();
        return;
      }
      let validateCoupon = {
        Couponcode: referCoupon.toUpperCase(),
        CouponPlan: "2",
        UserEmail: user_email,
      };

      const response = await ApiConnection.post(
        "/JobbsprangetCoupon/CouponValidation",
        validateCoupon
      );
      if (response.data.isSuccess) {
        if (
          // User Does not have Active subscription Redeem Button
          // go to Stripe with Referal code
          parseInt(subscriptionType) === 0 &&
          (parseInt(response.data.couponMsgType) === 4 ||
            parseInt(response.data.couponMsgType) === 5)
        ) {
          setIsValidCodeMsg("");
          setValidCodeMsg("Coupon code successfully applied!");
          setIsValidCode(true);
        } else if (
          // ALREADY AND SELF
          parseInt(response.data.couponMsgType) === 1 ||
          parseInt(response.data.couponMsgType) === 3
        ) {
          setUnlockModalShow(true);
          setAciveUserUseReferralCode(response.data.couponMsgType);
        } else {
          // GIFT LOCKED
          setUnlockModalShow(true);
          setAciveUserUseReferralCode(response.data.couponMsgType);
          setIsValidCodeMsg(false);
          setIsButtonDisabled(true);
        }
      } else {
        setIsValidCode(false);
        setUnlockModalShow(false);
        setIsValidCodeMsg(true);
        setValidCodeMsg(response.data?.couponDurationTxt);
        setAciveUserUseReferralCode(response.data.couponMsgType);
      }
    } catch (err) {
      console.log("Error in ApplyCoupon!!");
      setIsButtonDisabled(true);
    }
  };

  const getReferList = async () => {
    try {
      let getReferListObj = { UserEmail: user_email };
      const response = await ApiConnection.post(
        "/JobbsprangetEmail/GetReferralEmail",
        getReferListObj
      );
      if (response.data?.isSuccess) {
        var ReferArray = [];
        let completedCount = 0;
        let status = "";
        let ReferYourRewards = "1 month free";
        var referList = response.data?.referUserList;
        referList?.forEach((element) => {
          if (element.referStatus === "Completed") {
            completedCount += 1;
            status = "Successful";
            ReferYourRewards = "1 month free";
          } else if (element.referStatus.length > 10) {
            status = "Pending";
            ReferYourRewards = "Remind your friend to sign in";
          } else {
            status = "Pending";
            ReferYourRewards = "Remind your friend to sign in";
          }
          ReferArray.push({
            ReferUserName: element.referUserName,
            ReferUserEmail: element.referUserEmail,
            ReferStatus: status,
            ReferLogDate: element.referLogDate,
            ReferYourRewards: ReferYourRewards,
          });
        });
        setReferlist(ReferArray);
      }
    } catch (error) {
      console.log("Error in loading Refer list!!!");
    }
  };

  const handleReferralUserPayment = async () => {
    try {
      if (parseInt(subscriptionType) === 0) {
        if (!stripeAll) {
          return;
        }
        let _paymentType = 2;
        let _priceId = "";
        let _coupon = referCoupon.toUpperCase();
        console.log("userEmail" + user_email);
        console.log("_priceId" + _priceId);
        console.log("_paymentType" + _paymentType);
        console.log("_coupon#" + _coupon);

        if (_coupon) {
          let getfreeTrialCouponObj = {
            Couponcode: _coupon,
            UserEmail: user_email,
          };
          const response = await ApiConnection.post(
            "/JobbsprangetCoupon/FreeTrialCouponValidation",
            getfreeTrialCouponObj
          );
          if (response.data?.isSuccess) {
            Mixpanel.track(`YsfJobApp:FreeTrial Subscription for:${_coupon}`);
            sessionStorage.removeItem("JobApp_ReferId");
            setConfirmModalShow(true);
            return;
          }
        }

        if (isProd === "N") _priceId = "price_1PQPALJEaxc25mG4LKxN6Fxa";
        else _priceId = "price_1PXjoaJEaxc25mG43Y1T9b9T";

        let paymentRequest = {
          PriceId: _priceId,
          Quantity: 1,
          CouponId: _coupon,
          PaymentType: _paymentType,
          UserEmail: user_email,
          SuccessGift: 0,
        };
        const {
          data: { id, email },
        } = await ApiConnection.post(
          "/api/JobbsprangetPayment/create-checkout-session",
          paymentRequest
        );
        const { error } = await stripeAll.redirectToCheckout({
          sessionId: id,
        });
        if (error) {
          console.error("Stripe Checkout error:", error);
        } else {
          Mixpanel.track(
            `YsfJobApp:PayNow: '4 Weeks #' ${email} '#'${_coupon}`
          );
        }
      }
    } catch (ex) {
      console.log("Error in subscription!!");
    }
  };

  const handleGiftUnLocked = async () => {
    try {
      let earnMonths = 1;
      let getClaimObj = {
        UserEmail: user_email,
        NoOfMonth: earnMonths,
        ReferralCoupon: referCoupon.toUpperCase(),
      };
      setIsButtonDisabled(false);
      const response = await ApiConnection.post(
        "api/JobbsprangetPayment/ExtendBillingPeriod",
        getClaimObj
      );
      if (response.data?.isSuccess) {
        setIsButtonDisabled(true);
        setUnlockModalShow(false);
      } else {
        setIsButtonDisabled(false);
        setUnlockModalShow(true);
      }
      console.log(
        "subscriptionCancelMsg" + response.data?.subscriptionCancelMsg
      );
    } catch (error) {
      console.log("Error in loading Refer list!!!");
      setIsButtonDisabled(true);
      setUnlockModalShow(false);
    }
  };
  const handleSelfCoupon = () => {
    setUnlockModalShow(false);
  };

  const hndFreeTrial = async (value) => {
    sessionStorage.removeItem("JobApp_ReferId");
    setConfirmModalShow(value);
    navigate("/home");
    window.location.reload();
  };

  return (
    <div className="common-panel home-page">
      <div className="wallet-container">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-xxl-2">
            <Sidebar />
          </div>
          <div className="col-lg-9 col-xl-9 col-xxl-10">
            <div className="refer-container">
              <h3>Redeem your gifts</h3>
              <p>Unwrap the joy of your gift. Claim your rewards here!</p>

              <div className="row">
                <div className="col-lg-4">
                  <div className="reedem-lft">
                    <h4>Redeem a coupon code</h4>
                    <div className="refer-input">
                      <input
                        type="text"
                        placeholder="Refer Code"
                        maxLength="10"
                        value={referCoupon}
                        onChange={(e) =>
                          SetCouponVal(e.target.value.toUpperCase())
                        }
                        onKeyDown={handleKeyDown}
                      />
                      {isValidCode ? (
                        <img
                          src={IMAGE.confirm_check}
                          alt="arrow_right"
                          className="confirm_check"
                        />
                      ) : (
                        <button onClick={ReferralCodeHandler}>
                          <img src={IMAGE.arrow_right} alt="arrow_right" />
                        </button>
                      )}
                    </div>

                    {isValidCodeMsg && (
                      <h5 style={{ color: "red" }}>{validCodeMsg}</h5>
                    )}
                    {isValidCode && (
                      <>
                        <h5 style={{ color: "green" }}>{validCodeMsg}</h5>
                        <button
                          className="claimNow"
                          onClick={handleReferralUserPayment}
                        >
                          <img src={IMAGE.reedem_icon} alt="Redeem Now" />{" "}
                          Redeem Now
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="reedem-rht">
                    <label>List of Invited friend's</label>
                    <div className="box-border mt-3">
                      <div className="table-responsive table-style">
                        <table>
                          <thead>
                            <th>No.</th>
                            <th>friend's</th>
                            <th>Status</th>
                            <th>Your reward</th>
                          </thead>
                          <tbody>
                            {referlist &&
                              referlist.map((list, index) => {
                                return (
                                  <tr key={index}>
                                    <td> {index + 1}</td>
                                    <td> {list?.ReferUserEmail}</td>
                                    <td> {list?.ReferStatus}</td>
                                    <td>{list?.ReferYourRewards}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={unlockModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="subscription-modal language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            {parseInt(subscriptionType) > 0 &&
              (aciveUserUseReferralCode === 4 ||
                aciveUserUseReferralCode === 5) && (
                <>
                  <div className="col-lg-6">
                    <img
                      src={IMAGE.Happy_Announcement}
                      className="w-100"
                      alt="success_img"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="confirm-modal-content"
                      style={{ paddingLeft: "30px" }}
                    >
                      <h4>Gift unlocked!</h4>
                      <p>
                        Enjoy premium features and invite more friends for extra
                        free months!
                      </p>
                      <div className="d-flex mt-4">
                        {/* <button
                          disabled={isButtonDisabled}
                          className="BtnStyle_two"
                          onClick={handleGiftUnLocked}
                        >
                          Great!
                        </button> */}

                        {isButtonDisabled ? (
                          <button
                            className="BtnStyle_two"
                            onClick={handleGiftUnLocked}
                          >
                            {" "}
                            Great!
                          </button>
                        ) : (
                          <button className="BtnStyle_two">
                            <i className="fa fa-spinner fa-spin"></i> Processing
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            {aciveUserUseReferralCode === 1 && (
              <>
                <div className="col-lg-6">
                  <img
                    src={IMAGE.invalid_coupon}
                    className="w-100"
                    alt="success_img"
                  />
                </div>
                <div className="col-lg-6">
                  <div
                    className="confirm-modal-content"
                    style={{ paddingLeft: "30px" }}
                  >
                    <h4>Invalid coupon</h4>
                    <p>
                      {`Oops! You can’t use your own referral code. Come back with a valid coupon to redeem it in Settings > Redeem.`}
                    </p>
                    <div className="d-flex mt-4">
                      <button
                        className="BtnStyle_two"
                        onClick={handleSelfCoupon}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {aciveUserUseReferralCode === 3 && (
              <>
                <div className="col-lg-6">
                  <img
                    src={IMAGE.invalid_coupon}
                    className="w-100"
                    alt="success_img"
                  />
                </div>
                <div className="col-lg-6">
                  <div
                    className="confirm-modal-content"
                    style={{ paddingLeft: "30px" }}
                  >
                    <h4>Invalid coupon</h4>
                    <p>
                      {`This coupon has already been used. Come back with a valid coupon to redeem it in Settings > Redeem.`}
                    </p>
                    <div className="d-flex mt-4">
                      <button
                        className="BtnStyle_two"
                        onClick={handleSelfCoupon}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="subscription-modal language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={IMAGE.success_img}
                className="w-100"
                alt="success_img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="confirm-modal-content"
                style={{ paddingLeft: "30px" }}
              >
                <h4>Congratulations!</h4>
                <p>
                  You're activating your free package! Your subscription will
                  renew, and soon you can invite friends for extended premium
                  membership. Awesome!
                </p>
                <div className="d-flex mt-4">
                  <button
                    className="BtnStyle_two mx-2"
                    onClick={() => hndFreeTrial(false)}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Redeem;
