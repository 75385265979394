import React, { useEffect, useState } from "react";
import { IMAGE } from "../../common/Theme";
import DefaultLoader from "./DefaultLoader";
import ConcernLoader from "./ConcernLoader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ApiConnection from "../../common/ApiConnection";
import ListLoader from "./ListLoader";
import FormdataApi from "../../common/FormdataApi";
import { JobTitleAction } from "../../reducer/Dataflowreducer";
import { useSelector, useDispatch } from "react-redux";
import { toHaveAttribute } from "@testing-library/jest-dom/matchers";
import Loader from "../../common/Loader";
import { Mixpanel } from "../../auth/YsfMixPanel";

import copy from "copy-to-clipboard";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";

const Concern = () => {
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState("");
  const [toggle, settoggle] = useState(false);
  const [Concernlist, setConcernlist] = useState([]);
  const [GainList, setGainList] = useState([]);
  const [saveConcernList, setSaveConcernList] = useState("");
  const [saveGainList, setsaveGainList] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [btnconcernLoader, setbtnconcernLoader] = useState(false);
  const [btngainLoader, setbtngainLoader] = useState(false);
  const [gainListLoader, setGainListLoader] = useState(false);
  const [cname, setcname] = useState("");
  const [ConcerninputList, setConcerninputList] = useState([]);
  const [GaininputList, setGaininputList] = useState([]);

  const [firstlanding, setfirstlanding] = useState(false);
  const [isSaveConcern, setIsSaveConcern] = useState(true);
  const [isSaveGain, setIsSaveGain] = useState(true);
  const [isSaveConcernFlag, setIsSaveConcernFlag] = useState(false);
  const [isSaveGainFlag, setIsSaveGainFlag] = useState(false);

  const [activeTab, setActiveTab] = useState("tab1");
  const [nodata, setNodata] = useState(true);
  const [loading, setloading] = useState(false);
  const [output, setoutput] = useState(false);

  const [showGenerateBtn, setShowGenerateBtn] = useState(false);
  const [QuestionList, setQuestionList] = useState([]);
  const [indexin, setIndexIn] = useState(0);

  const [conclusionTxt, setConclusionTxt] = useState("");
  const [bodytext, setbodytext] = useState("");
  const [introtext, setIntroTxt] = useState("");

  const [questError, setQuestError] = useState(false);
  const [introError, setIntroError] = useState(false);

  const [shortIntroError, setShortIntroError] = useState(false);
  const [shortIntroLoader, setShortIntroLoader] = useState(false);

  const [shortIntroTxt, setShortIntroTxt] = useState("");
  const [shortIntroBodyTxt, setShortIntroBodyTxt] = useState("");
  const [shortIntroConclusionTxt, setShortIntroConclusionTxt] = useState("");

  const [saveQuestSeq, setSaveQuestSeq] = useState("");
  const [saveQuest, setSaveQuest] = useState("");
  const [saveAnswer, setSaveAnswer] = useState("");
  const [saveUserAnswer, setSaveUserAnswer] = useState("");
  const [isReUploadCV, setIsReUploadCV] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [cvTxt, setCVTxt] = useState("");
  const [regenerateDisable, setRegenerateDisable] = useState(false);
  const [shortIntroContent, setShortIntroContent] = React.useState("");
  const [cvReUploadLoader, setCVReUploadLoader] = useState(false);
  const [showMyFinishCounter, setShowMyFinishCounter] = useState(0);
  const [showConcernSave, setShowConcernSave] = useState("");
  const [showGainSave, setShowGainSave] = useState("");
  const [disableQASave, setDisableQASave] = useState("");
  const [clickTab, setClickTab] = useState("QA");
  const [introActiveTab, setIntroActiveTab] = useState("full");

  const fetchReducer = useSelector((state) => state.dataflowreducer);
  var user_email = localStorage.getItem("ysf_email");
  var jobAppLang = localStorage.getItem("jobAppLang");

  let navigate = useNavigate();
  const location = useLocation();
  let jobId = location.state;
  const dispatch = useDispatch();
  const [CVFileName, setCVFileName] = useState("File Name ...");
  var srNoregex = new RegExp(`\\d+`);
  var hashRegex = new RegExp(`\\#+`);
  var srNoDotregex = /\d+\.\s+/;
  var splitRegex = /\d+\.\s*/;
  var splitGainRegex = /\d+\.\s+/;

  // CALL GENERATE AND GAIN AND CONERN
  useEffect(() => {
    try {
      if (jobId.InterviewDone === "Y") {
        GetConcernGainIntro();
        setIsReUploadCV(true);
        setShowMyFinishCounter(0);
      } else {
        setShowMyFinishCounter(0);
        setcname(jobId.CompanyJobTitle.split("-")[0]);
        GenerateConcern();
        GenerateGain();
      }

      // setShowMyFinishCounter((count) => count + 1);
    } catch (error) {
      console.log("Error!");
    } finally {
      Mixpanel.identify(localStorage.getItem("ysf_email"));
      Mixpanel.people.set({
        $first_name: localStorage.getItem("ysf_name"),
      });
    }
  }, [jobAppLang]);

  // Replace CV Start
  const ReplaceCVHnd = async (e) => {
    try {
      var file = e.target.files[0];
      var fileName = file.name;
      setCVFileName(fileName);
      let data = new FormData();
      data.append("files", file);
      setCVReUploadLoader(true);
      const response = await FormdataApi.post(
        "/JobbsprangetFileUploadUser/ReadDocFile",
        data
      );
      if (response) {
        setCVTxt(response.data);
        setRegenerateDisable(true);
        setCVReUploadLoader(false);
      }
    } catch (error) {
      //console.log("Error!")
    } finally {
      Mixpanel.track("YsfJobApp:Re-Upload CV");
    }
  };
  // Replace CV End

  //GET SAVED CONCERN
  const GetConcernGainIntro = async () => {
    setListLoader(true);
    try {
      let getConcernGainQuestionIntroObj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
      };
      const response = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGetConcernGainFullShortIntro",
        getConcernGainQuestionIntroObj
      );
      if (response.data?.isSuccess) {
        setIsSave(true);
        setListLoader(false);
        setfirstlanding(true);
        setIsSaveConcernFlag(false);
        setIsSaveConcern(false);
        setIsSaveGainFlag(false);
        setIsSaveGain(false);

        setNodata(false);
        setloading(false);
        setoutput(true);
        setIsReUploadCV(true);
        setcname(response.data?.jobPostCompanyName);
        dispatch(
          JobTitleAction(
            response.data?.jobPostCompanyName +
              " - " +
              response.data?.jobPostTitle
          )
        );

        if (response.data.jobPostSelectedConcern) {
          var concernData = response.data.jobPostSelectedConcern;
          var concernSplitData = concernData.split("||");
          //console.log("length"+concernSplitData);
          var concernTmpArray = [];
          concernSplitData.forEach((element, index) => {
            var concernTxt = element.trim(); //element.split(":")?.[1]
            concernTxt = concernTxt.replace(".", "");
            concernTxt = removeDoubleAsterisks(concernTxt);
            if (concernTxt.length > 20) {
              concernTmpArray.push({
                id: index,
                text: concernTxt.trim(),
                toggleSelected: true,
              });
            }
          });
          setConcernlist(concernTmpArray);
          setShowMyFinishCounter((count) => count + 1);
        }

        //SET GAINS
        var gainData = response.data?.jobPostSelectedGains;
        if (response.data?.jobPostSelectedGains) {
          gainData = response.data?.jobPostSelectedGains;
          //console.log(gainData);
          var gainSplitData = gainData.split("||");
          //console.log("gainSplitData#"+gainSplitData);
          //console.log("gainSplitData.Length#"+gainSplitData.length);
          var gainTmpArray = [];
          gainSplitData.forEach((element, index) => {
            var gainTxt = element.trim(); //  element.split(":")?.[1]
            gainTxt = gainTxt.replace(".", "");
            gainTxt = removeDoubleAsterisks(gainTxt);
            gainTxt = removeHash(gainTxt.trim());
            if (gainTxt.length > 10) {
              gainTmpArray.push({
                id: index,
                text: gainTxt.trim(),
                toggleSelected: true,
              });
            }
          });
          setGainList(gainTmpArray);
          setsaveGainList(gainTmpArray);
          setShowMyFinishCounter((count) => count + 1);
        } else if (
          !response.data.jobPostSelectedGains &&
          response.data.jobPostGains
        ) {
          populateGainsALL(response, true);
          //setfirstlanding(true);
        }

        //SET FULL INTRO
        populateFullIntroducationVersion("Get", response);

        //SET SHORT INTRO
        populateShortIntroducationVersion("Get", response);

        //GET USER CVNAME
        var userCVName = response.data?.userCVName;
        //console.log(userCVName);
        setCVFileName(userCVName);

        const qaResponse = await ApiConnection.post(
          "/JobbsprangetUser/JobbsprangetGetQuestionAnswer",
          getConcernGainQuestionIntroObj
        );
        console.log(qaResponse.data);
        setoutput(false);
        setNodata(true);
        if (qaResponse.data[0].isSuccess && qaResponse.data.length > 0) {
          setoutput(true);
          setNodata(false);
          var qaData = qaResponse.data;
          var QuestTempArray = [];
          qaData &&
            qaData.forEach((list, index) => {
              var questionset = list;
              QuestTempArray.push({
                id: index,
                qno: questionset.jobQuestionSeq,
                question: questionset.jobQuestion,
                ans: questionset.jobAnswer,
                suggestedans: questionset.jobUserAnswer,
              });
            });
          setQuestionList(QuestTempArray);
          setShowMyFinishCounter((count) => count - 1);
        } else {
          setoutput(false);
          setNodata(true);
          setShowGenerateBtn(false);
          setIsSaveConcernFlag(true);
          setIsSaveGainFlag(true);
          setIsSave(false);
        }
      }
    } catch (e) {
      setListLoader(false);
    }
  };
  //GENERATE CONCERN
  const GenerateConcern = async () => {
    setListLoader(true);
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      let obj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        Language: jobAppLang,
      };

      const concernResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetConcrenApi",
        obj
      );
      console.log(
        "concrenResponse_data#" + concernResponse.data.jobPostReponse
      );
      if (concernResponse.data?.isSuccess) {
        setListLoader(false);
        setcname(concernResponse.data?.jobCompany);
        dispatch(
          JobTitleAction(
            concernResponse.data?.jobCompany +
              " - " +
              concernResponse.data?.jobPostTitle
          )
        );
        populateConcernALL(concernResponse);
      } else {
        setListLoader(false);
        GenerateConcern();
      }
    } catch (e) {
      setListLoader(false);
    }
  };

  //GENERATE GAIN
  const GenerateGain = async () => {
    setListLoader(true);
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      let obj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        Language: jobAppLang,
      };
      const gainResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGainsApi",
        obj
      );
      console.log("gainResponse_data#" + gainResponse.data.jobPostReponse);
      if (gainResponse.data?.isSuccess) {
        setListLoader(false);
        populateGainsALL(gainResponse);
      } else {
        setListLoader(false);
        GenerateGain();
      }
    } catch (e) {
      setListLoader(false);
    }
  };

  const ConcernListHandle = (row) => {
    const update = Concernlist.map((item) => {
      if (row.id !== item.id) {
        return item;
      } else {
        return {
          ...item,
          toggleSelected: !item.toggleSelected,
        };
      }
    });
    setConcernlist(update);
    let concernTxt = "";
    var separator = "||";

    update.forEach((item) => {
      if (item.toggleSelected) {
        concernTxt += item.text + separator;
      }
    });
    setSaveConcernList(concernTxt);
    setShowConcernSave(concernTxt);
  };

  const GainListHandle = (row) => {
    const update = GainList.map((item) => {
      if (row.id !== item.id) {
        return item;
      } else {
        return {
          ...item,
          toggleSelected: !item.toggleSelected,
        };
      }
    });
    setGainList(update);

    let gainString = "";
    var separator = "||";

    update.forEach((item) => {
      if (item.toggleSelected) {
        gainString += item.text + separator;
      }
    });
    setsaveGainList(gainString);
    setShowGainSave(gainString);
  };

  const ConcernSave = async () => {
    window.scrollTo(0, 0);
    setConcernlist([...Concernlist, ...ConcerninputList]);
    setbtnconcernLoader(true);
    let obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      UserSelectedConcern: saveConcernList,
      UserSelectedGain: "",
    };
    //console.log("saveConcernList#"+saveConcernList);

    try {
      const response = await ApiConnection.post(
        "JobbsprangetUser/JobbsprangetSaveConernGainsApi",
        obj
      );
      if (response.data?.isSuccess) {
        setbtnconcernLoader(false);
        setIsSaveConcern(false);
        setIsSaveConcernFlag(true);
        setfirstlanding(true);
        setConcerninputList([]);
        if (GainList.length > 0) setGainListLoader(false);
        else setGainListLoader(true);
      }
    } catch (err) {
      console.log("error in concern log!");
    }
  };

  const ConcernEdit = () => {
    setIsSaveConcern(true);
    setIsSaveConcernFlag(false);
    if (isSaveGainFlag) {
      setIsSaveGain(false);
    } else {
      setIsSaveGain(true);
      setfirstlanding(false);
    }
  };

  const GainSave = async () => {
    window.scrollTo(0, 0);
    setGainList([...GainList, ...GaininputList]);
    setbtngainLoader(true);
    //console.log("saveGainList#"+saveGainList);
    let obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      UserSelectedConcern: "",
      UserSelectedGain: saveGainList,
      Language: jobAppLang,
    };

    try {
      const response = await ApiConnection.post(
        "JobbsprangetUser/JobbsprangetSaveConernGainsApi",
        obj
      );

      if (response.data?.isSuccess) {
        setbtngainLoader(false);
        setIsSaveGain(false);
        setIsSaveGainFlag(true);
        setGaininputList([]);
        setShowGenerateBtn(false);
        setGainListLoader(false);
      }
    } catch (err) {}
  };

  const GainEdit = () => {
    setIsSaveGain(true);
    setIsSaveGainFlag(false);
    setIsSaveConcern(false);
    setIsSaveConcernFlag(true);
    setGainListLoader(false);
  };

  const AddConcernHandle = () => {
    const rawinput = {
      id: Concernlist.length + ConcerninputList.length,
      text: "",
      toggleSelected: true,
    };
    setConcerninputList([...ConcerninputList, rawinput]);
  };

  const concerninputHandle = (e, key, index) => {
    var newValue = [...ConcerninputList];
    newValue[index][key] = e.target.value;
    setConcerninputList(newValue);
    if (newValue !== "") {
      setShowConcernSave(newValue);
    }
  };

  const AddGainHandle = () => {
    //console.log("GainList.length"+GainList.length);
    const rawInput = {
      id: GainList.length + GainList.length,
      text: "",
      toggleSelected: true,
    };
    setGaininputList([...GaininputList, rawInput]);
  };

  const gainInputHandle = (e, key, index) => {
    var newValue = [...GaininputList];
    newValue[index][key] = e.target.value;
    setGaininputList(newValue);
    if (newValue !== "") {
      setShowGainSave(newValue);
    }
  };

  const GenerateInterviewIntroducationHandle = async () => {
    try {
      jobAppLang = localStorage.getItem("jobAppLang");
      setNodata(false);
      setloading(true);
      setoutput(false);
      setIntroError(false);
      setShortIntroError(false);
      setShortIntroLoader(true);

      let generate_Quest_Intro_Short_Obj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        Language: jobAppLang,
      };
      const questionResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateQuestionApi",
        generate_Quest_Intro_Short_Obj
      );
      //console.log("questionResponse_data_jobPostReponse#"+questionResponse.data?.jobPostReponse);

      // Get Question data
      var questionDataArray;
      if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray = questionResponse.data?.jobPostReponse;
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(srNoDotregex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(srNoDotregex);
        //console.log("questionDataArray#",questionDataArray);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(hashRegex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(hashRegex);
        //console.log("questionDataArray#",questionDataArray);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(srNoregex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(srNoregex);
        questionDataArray.slice(0, 1);
        //console.log("questionDataArray#",questionDataArray);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split("\n\n").length > 1 &&
        (questionResponse.data?.jobPostReponse
          ?.split("\n\n")[1]
          .includes("Question:") ||
          questionResponse.data?.jobPostReponse
            ?.split("\n\n")[1]
            .includes("Q:")) &&
        (questionResponse.data?.jobPostReponse
          ?.split("\n\n")[1]
          .includes("Answer:") ||
          questionResponse.data?.jobPostReponse
            ?.split("\n\n")[1]
            .includes("A:"))
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split("\n\n");
        console.log("questionDataArray#", questionDataArray);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else {
        setloading(false);
        setoutput(true);
        setQuestError(true);
        setShowGenerateBtn(true);
        //console.log("setQuestError");
        TryAgain_GenerateQuestionAnswer();
      }

      // Get introresponse data
      const introResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateIntroducationApi",
        generate_Quest_Intro_Short_Obj
      );
      populateFullIntroducationVersion("Generate", introResponse);

      // GET short intro Response
      const shortIntroResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateShortIntroApi",
        generate_Quest_Intro_Short_Obj
      );
      populateShortIntroducationVersion("Generate", shortIntroResponse);
    } catch (e) {
      setloading(false);
      setoutput(false);
      setShowGenerateBtn(false);
      setIntroError(false);
      setShortIntroError(false);

      //console.log("ERROR IN QUESTION");
    } finally {
      Mixpanel.track("YsfJobApp:Generate QA Introducation");
    }
  };

  const AnswerHandle = (userAnswer, key, index_) => {
    //console.log("AnswerHandle-value",userAnswer);
    //console.log("AnswerHandle-key",key);
    //console.log("AnswerHandle-index",index_);
    var rawdata = [...QuestionList];
    rawdata[index_][key] = userAnswer;
    setQuestionList(rawdata);
    setQuestion(userAnswer);
    setDisableQASave(userAnswer);
    setSaveUserAnswer(userAnswer.trim());
  };

  const InsertHandle = (QNo, Question, Answer, key, index_) => {
    //console.log("QNo-value",QNo);
    //console.log("InsertHandle-Question",Question);
    //console.log("InsertHandle-Answer",Answer);
    //console.log("InsertHandle-key",key);
    //console.log("InsertHandle-index",index_);

    var rawdata = [...QuestionList];
    rawdata[index_][key] = Answer.trim();

    setQuestionList(rawdata);
    setQuestion(Answer);

    setSaveQuestSeq(QNo);
    setSaveQuest(Question.trim());
    setSaveAnswer(Answer.trim());
    setSaveUserAnswer("");
    setDisableQASave(Answer);
  };

  const btnFinsihInterviewPreparationHandle = async () => {
    try {
      let interviewFinishObj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
      };

      const response = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetFinishInterviewPreparationSaveApi",
        interviewFinishObj
      );
      if (response.data?.isSuccess) {
        //console.log("Finish interview preparation.");
        navigate("/home");
      }
    } catch (error) {
      //console.log("Error in Finish interview preparation.");
    }
  };

  const TryAgain_GenerateQuestionAnswer = async () => {
    setNodata(false);
    setloading(true);
    setoutput(false);
    setQuestError(false);
    jobAppLang = localStorage.getItem("jobAppLang");
    let generate_Quest_Intro_Short_Obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      Language: jobAppLang,
    };
    try {
      const questionResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateQuestionApi",
        generate_Quest_Intro_Short_Obj
      );
      // Get Question data
      var questionDataArray;
      console.log("questionDataArray#" + questionResponse.data?.jobPostReponse);

      if (questionResponse.data?.isSuccess) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray = questionResponse.data?.jobPostReponse;
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(srNoDotregex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(srNoDotregex);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(srNoregex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(hashRegex);
        questionDataArray.slice(0, 1);
        //console.log("questionDataArray#",questionDataArray);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split(hashRegex).length > 1
      ) {
        setloading(false);
        setoutput(true);
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split(hashRegex);
        questionDataArray.slice(0, 1);
        populateQuestionAnswerALL("SrNo", questionDataArray);
      } else if (
        questionResponse.data?.isSuccess &&
        questionResponse.data?.jobPostReponse?.split("\n\n").length > 1
      ) {
        setloading(false);
        setoutput(true);
        //questionDataArray = questionResponse.data?.jobPostReponse?.split('\n\n').filter(section => section.trim() !== '');
        questionDataArray =
          questionResponse.data?.jobPostReponse?.split("\n\n");
        populateQuestionAnswerALL("SrNo", questionDataArray);
        //NEWpopulateQuestionAnswerALL("SrNo",questionDataArray);
      } else {
        setloading(false);
        setQuestError(true);
        setShowGenerateBtn(true);
        setoutput(true);
      }
    } catch (error) {
      setloading(false);
      setQuestError(true);
      setShowGenerateBtn(true);
      setoutput(true);
    }
  };
  //Get Intro data
  const TryAgain_GenerateIntro = async () => {
    setNodata(false);
    setloading(true);
    setoutput(false);
    setIntroError(false);
    jobAppLang = localStorage.getItem("jobAppLang");
    let generate_Quest_Intro_Short_Obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      Language: jobAppLang,
    };
    // Get introresponse data
    const introResponse = await ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetGenerateIntroducationApi",
      generate_Quest_Intro_Short_Obj
    );
    populateFullIntroducationVersion("Generate", introResponse);
  };
  //Get Short Intro data
  const TryAgain_GenerateShortIntro = async () => {
    setNodata(false);
    setloading(true);
    setoutput(false);
    setShortIntroError(false);
    setShortIntroLoader(true);
    jobAppLang = localStorage.getItem("jobAppLang");
    let generate_Quest_Intro_Short_Obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      Language: jobAppLang,
    };
    // GET short intro Response
    const shortIntroResponse = await ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetGenerateShortIntroApi",
      generate_Quest_Intro_Short_Obj
    );
    populateShortIntroducationVersion("Generate", shortIntroResponse);
  };

  const FullVersionIntro = async () => {
    setIntroError(false);
    setActiveTab("tab1");
    setClickTab("QA");
  };

  const ShortVersionIntro = async () => {
    setIntroError(false);
    if (shortIntroContent) setShortIntroLoader(false);
    else setShortIntroLoader(true);
    setActiveTab("tab2");
    setClickTab("Intro");
    setIntroActiveTab("Intro");
  };

  const SaveQuestionAnswerHnd = async (QNo, Question, Answer, UserAnswer) => {
    //console.log("saveQuestSeq#"+saveQuestSeq);
    //console.log("saveQuest#"+saveQuest);
    //console.log("saveAnswer#"+saveAnswer);
    //console.log("saveUserAnswer#"+UserAnswer);
    setQuestion("");
    try {
      let save_QuestAnsw_Obj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        JobQuestionSeq: QNo,
        JobQuestion: Question,
        JobAnswer: Answer,
        JobUserAnswer: UserAnswer,
      };
      //console.log("save_QuestAnsw_Obj#"+save_QuestAnsw_Obj);
      const questAnswerResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetQuestionAnswerApi",
        save_QuestAnsw_Obj
      );
      // Save Question Answer data
      if (questAnswerResponse.data?.isSuccess) {
        //console.log("saveAnswer#");
        setQuestion(saveQuest);
        setDisableQASave("");
        Mixpanel.track("YsfJobApp:QA" + QNo);
      } else {
        setQuestion(saveQuest);
        setDisableQASave("");
        //console.log("Error In saving");
      }
    } catch (error) {
      //console.log("Error in Saving Answer#");
    }
  };
  // Save Question Answer data
  const SaveAllQuestionAnswer = (QuestTmpArray_) => {
    QuestTmpArray_ &&
      QuestTmpArray_.forEach((list) => {
        let save_QuestAnsw_Obj = {
          UserEmail: user_email,
          JobPostId: jobId.id,
          JobQuestionSeq: list.qno,
          JobQuestion: list.question,
          JobAnswer: list.ans,
          JobUserAnswer: "",
        };
        var saveRespose = ApiConnection.post(
          "/JobbsprangetUser/JobbsprangetQuestionAnswerApi",
          save_QuestAnsw_Obj
        );
      });
  };
  const SaveFullShortIntro = (FullIntro, ShortIntro) => {
    //console.log("FullIntro#"+FullIntro);
    //console.log("ShortIntro#"+ShortIntro);
    let save_FullShortIntro_Obj = {
      UserEmail: user_email,
      JobPostId: jobId.id,
      JobFullIntro: FullIntro,
      JobShortIntro: ShortIntro,
    };
    ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetFullShortIntroApi",
      save_FullShortIntro_Obj
    );
  };

  const populateQuestionAnswerALL = async (SrNo, questionDataArray) => {
    console.log("questionDataArray#", questionDataArray);
    console.log("SrNo", SrNo);

    try {
      const QuestTempArray = [];
      const lines = questionDataArray.split("\n").filter((line) => line !== "");

      let quest = "";
      let answ = "";
      var _index = 0;
      var _qIndex = 0;
      var checkFirstLine = 0;

      lines.forEach((line) => {
        console.log("line#" + line);
        if (
          checkFirstLine === 0 &&
          (line.trim() !== "Question" ||
            line.trim() !== "Question:" ||
            line.trim() !== "Answer" ||
            line.trim() !== "Answer:" ||
            line.trim() !== "Fråga" ||
            line.trim() !== "Svar" ||
            line.trim() !== "Domanda" ||
            line.trim() !== "Risposta")
        ) {
          checkFirstLine = 1;
          return;
        }
        if (
          line.trim() === "Question" ||
          line.trim() === "Answer" ||
          line.trim() === "Question:" ||
          line.trim() === "Answer:" ||
          line.trim() === "Fråga" ||
          line.trim() === "Svar" ||
          line.trim() === "Domanda" ||
          line.trim() === "Risposta"
        )
          return;

        if (!quest) {
          quest = line.replace("###", "").trim();
        } else if (quest && !answ) {
          answ = line.trim();
        }

        if (quest && answ) {
          //console.log("_index", QuestTempArray.length);
          //console.log("_qindex", QuestTempArray.length + 1);
          _qIndex = _qIndex + 1;
          _index = _qIndex - 1;
          QuestTempArray.push({
            id: _index,
            qno: _qIndex,
            question: quest,
            ans: answ,
            suggestedans: "",
          });
          quest = "";
          answ = "";
        }
      });

      console.log("#QuestTempArray#", QuestTempArray.length);

      if (QuestTempArray.length > 0) {
        setQuestionList(QuestTempArray);
        setShowMyFinishCounter((count) => count + 1);
        await SaveAllQuestionAnswer(QuestTempArray);
      } else {
        TryAgain_GenerateQuestionAnswer();
      }
    } catch (e) {
      console.log("Error In Population QA#", e);
      TryAgain_GenerateQuestionAnswer();
    }
  };

  // Re Upload
  const populateConcernALL = async (concernResponse_) => {
    setListLoader(false);
    try {
      setcname(concernResponse_.data?.jobCompany);
      dispatch(
        JobTitleAction(
          concernResponse_.data?.jobCompany +
            " - " +
            concernResponse_.data?.jobPostTitle
        )
      );
      var concernSplitData;
      var concrenTempArray = [];
      var concernTxt;
      if (
        concernResponse_ &&
        concernResponse_.data.jobPostReponse.split(splitRegex).length > 0
      ) {
        concernSplitData =
          concernResponse_.data.jobPostReponse?.split(splitRegex);
        concernSplitData.splice(0, 1);
        concernSplitData.forEach((element, index) => {
          concernTxt = element.trim();
          //console.log("concernTxt#"+concernTxt);
          //concernTxt=concernTxt.replace(".",'');
          concernTxt = removeDoubleAsterisks(concernTxt.trim());
          if (concernTxt.length > 20) {
            concrenTempArray.push({ id: index, text: concernTxt.trim() });
          }
        });
        setConcernlist(concrenTempArray);
      } else {
        concernSplitData = concernResponse_.data?.jobPostReponse?.split("\n\n");
        concernSplitData.splice(0, 1);
        concernSplitData.splice(concernSplitData?.length - 1, 1);
        concernSplitData.forEach((element, index) => {
          //console.log("concernTxt##"+element);
          concernTxt = element.split(":")[1].trim();
          //concernTxt=concernTxt.replace(".",'');
          concernTxt = removeDoubleAsterisks(concernTxt.trim());
          if (concernTxt.length > 20) {
            concrenTempArray.push({
              id: index,
              text: concernTxt.trim(),
            });
          }
        });
        setConcernlist(concrenTempArray);
      }
    } catch (e) {
      setListLoader(false);
    }
  };

  const populateGainsALL = async (_gainResponse, _bRegen) => {
    setListLoader(false);
    try {
      var gainRes = _gainResponse.data?.jobPostReponse;
      gainRes = removeHash(gainRes);
      var gainSplitData;
      var gainTempArray = [];
      var gainTxt;

      if (gainRes.split(splitGainRegex).length > 0) {
        gainSplitData = gainRes.split(splitGainRegex);
        if (gainSplitData.length > 0) {
          gainSplitData.splice(0, 1);
          gainSplitData.splice(gainSplitData?.length - 1, 1);
          gainSplitData.forEach((element, index) => {
            gainTxt = element.trim();
            //console.log("gainTxt#"+gainTxt);
            gainTxt = gainTxt.replace(".", "");
            gainTxt = removeDoubleAsterisks(gainTxt.trim());
            gainTxt = removeHash(gainTxt.trim());
            if (gainTxt.length > 30) {
              gainTempArray.push({
                id: index,
                text: gainTxt.trim(),
              });
            }
          });
          setGainList(gainTempArray);
          setGainListLoader(false);
        }
      } else {
        gainSplitData = gainRes.split("\n\n");
        if (gainSplitData.length > 0) {
          gainSplitData.splice(0, 1);
          gainSplitData.splice(gainSplitData?.length - 1, 1);
          gainSplitData.forEach((element, index) => {
            //console.log("gainTxt##"+element);
            gainTxt = element.trim().split(":")?.[1];
            gainTxt = gainTxt.replace(".", "");
            gainTxt = removeDoubleAsterisks(gainTxt);
            gainTxt = removeHash(gainTxt.trim());
            if (gainTxt.length > 30) {
              gainTempArray.push({
                id: index,
                text: gainTxt.trim(),
              });
            }
          });
          setGainList(gainTempArray);
          setGainListLoader(false);
          setShowMyFinishCounter((count) => count + 1);
        }
        if (gainSplitData.length < 1) {
          GenerateGain();
        }
      }
    } catch (e) {
      setListLoader(false);
      setGainListLoader(false);
    }
  };

  const copyShortIntro = () => {
    copy(shortIntroContent.replace("<br />", ""));
  };

  const populateFullIntroducationVersion = async (
    getGenerate,
    fullIntroResponse
  ) => {
    setListLoader(false);
    try {
      var introDataConclution;
      var introDatabody;
      var introTxt;
      var introResponseData;
      //setIntroActiveTab("Intro");
      if (getGenerate === "Get")
        introResponseData = fullIntroResponse.data?.jobPostFullIntro;
      else introResponseData = fullIntroResponse.data?.jobPostReponse;

      console.log("fullIntroResponse#" + introResponseData);
      if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("**Introduction**") &&
        introResponseData.includes("**Body**") &&
        introResponseData.includes("**Conclusion**")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("**Conclusion**");
        setConclusionTxt(introDataConclution[1]);

        introDatabody = introDataConclution[0].split("**Body**");
        setIntroTxt(introDatabody[0].split("**Introduction**")[1].trim());
        setbodytext(introDatabody[1]);
        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("**Introduction:**") &&
        introResponseData.includes("**Body:**") &&
        introResponseData.includes("**Conclusion:**")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("**Conclusion:**");
        setConclusionTxt(introDataConclution[1]);

        introDatabody = introDataConclution[0].split("**Body:**");
        introTxt = introDatabody[0]
          .split("**Introduction:**")[1]
          .replace("---", "");
        setIntroTxt(introTxt.trim());
        setbodytext(introDatabody[1]);
        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("## Introduction") &&
        introResponseData.includes("## Body") &&
        introResponseData.includes("## Conclusion")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("## Conclusion");
        setConclusionTxt(introDataConclution[1].trim());

        introDatabody = introDataConclution[0].split("## Body");
        introTxt = introDatabody[0]
          .split("## Introduction")[1]
          .trim()
          .replace("#", "")
          .replace("---", "");
        setIntroTxt(introTxt.trim());
        setbodytext(introDatabody[1].trim());
        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("### Introduction") &&
        introResponseData.includes("### Body") &&
        introResponseData.includes("### Conclusion")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("### Conclusion");
        setConclusionTxt(introDataConclution[1].trim());

        introDatabody = introDataConclution[0].split("### Body");
        introTxt = introDatabody[0]
          .split("### Introduction")[1]
          .trim()
          .replace("#", "")
          .replace("---", "");
        setIntroTxt(introTxt.trim());
        setbodytext(introDatabody[1].trim());
        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("### **Conclusion**") &&
        introResponseData.includes("### **Body**") &&
        introResponseData.includes("### **Introduction**")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("### **Conclusion**");
        setConclusionTxt(introDataConclution[1]);

        introDatabody = introDataConclution[0].split("### **Body**");
        introTxt = introDatabody[0]
          .split("### **Introduction**")[1]
          .trim()
          .replace("#", "")
          .replace("---", "");
        setIntroTxt(introTxt.trim());
        setbodytext(introDatabody[1]);
        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("Introduction") &&
        introResponseData.includes("Body") &&
        introResponseData.includes("Conclusion")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("Conclusion");
        setConclusionTxt(introDataConclution[1]);

        introDatabody = introDataConclution[0].split("Body");
        setIntroTxt(introDatabody[0].split("Introduction")[1].trim());
        setbodytext(introDatabody[1]);
        setShowMyFinishCounter((count) => count + 1);
      } else if (fullIntroResponse.data?.isSuccess) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("###");
        setIntroTxt(introDataConclution[0].trim());
        setbodytext(introDataConclution[1].trim());
        setConclusionTxt(introDataConclution[2].trim());

        setShowMyFinishCounter((count) => count + 1);
      } else if (
        fullIntroResponse.data?.isSuccess &&
        introResponseData.includes("<Introduction>") &&
        introResponseData.includes("<Body>") &&
        introResponseData.includes("<Conclusion>")
      ) {
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setIntroTxt("");
        setbodytext("");
        setConclusionTxt("");

        introDataConclution = introResponseData.split("<Conclusion>");
        setConclusionTxt(introDataConclution[1]);

        introDatabody = introDataConclution[0].split("<Body>");
        setIntroTxt(introDatabody[0].split("<Introduction>")[1].trim());
        setbodytext(introDatabody[1].trim());
        setShowMyFinishCounter((count) => count + 1);
      } else {
        setloading(false);
        setoutput(true);
        setIntroError(true);
        setShowGenerateBtn(true);
      }

      if (getGenerate === "Generate")
        await SaveFullShortIntro(introResponseData, "FULL_INTRO");
      else setShowMyFinishCounter((count) => count - 1);
    } catch (e) {
      console.log("Error-IntroResponse");
    }
  };

  const populateShortIntroducationVersion = async (
    getGenerate,
    shortIntroResponse
  ) => {
    setListLoader(false);
    setShortIntroContent("");
    try {
      var introResponseData;
      if (getGenerate === "Get") {
        introResponseData =
          (shortIntroResponse.data?.jobPostShortIntro).slice(1);
      } else
        introResponseData = (shortIntroResponse.data?.jobPostReponse).slice(0);

      //console.log("ShortIntroResponse#"+introResponseData);
      var IntroTempArray = [];
      if (shortIntroResponse.data?.isSuccess) {
        //console.log("index#");
        setShortIntroLoader(false);
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setShortIntroTxt("");
        setShortIntroBodyTxt("");
        setShortIntroConclusionTxt("");

        const sections = introResponseData
          .split("\n\n")
          .filter((section) => section.trim() !== "");
        //console.log("sections#"+sections.length);
        sections.forEach((item, index) => {
          //console.log("item#"+item+"itemIndex#"+index);
          let getTxt = item.split("\n");
          //console.log("getTxt#"+getTxt.length);
          getTxt.forEach((_item, index) => {
            if (getTxt.length === 1) {
              if (
                _item.trim().includes("Certainly!") ||
                _item.trim().includes("---") ||
                _item.trim().includes("**")
              ) {
                console.log("index#" + _item.trim());
              } else
                IntroTempArray.push(
                  _item.trim().replace('"', "").replace('"', "")
                );
            } else {
              if (index === 1) {
                IntroTempArray.push(
                  _item.trim().replace('"', "").replace('"', "")
                );
              }
            }
          });
        });
        if (IntroTempArray.length > 0) {
          let shortTxt =
            IntroTempArray[0].trim() +
            "\n\n" +
            IntroTempArray[1].trim() +
            "\n\n" +
            IntroTempArray[2].trim() +
            "\n\n" +
            IntroTempArray[3].trim();
          setShortIntroContent(shortTxt.trim());
          setShowMyFinishCounter((count) => count + 1);
        }
      } else if (
        shortIntroResponse.data?.isSuccess &&
        introResponseData.includes("Introduction") &&
        introResponseData.includes("Experiences") &&
        introResponseData.includes("Results") &&
        introResponseData.includes("Motivation")
      ) {
        console.log("#sections#");
        setShortIntroLoader(false);
        setloading(false);
        setoutput(true);
        setShowGenerateBtn(true);
        setShortIntroTxt("");
        setShortIntroBodyTxt("");
        setShortIntroConclusionTxt("");

        const sections = introResponseData
          .split("\n\n")
          .filter((section) => section.trim() !== "");
        //console.log("sections#"+sections.length);
        sections.forEach((item, index) => {
          console.log("item#" + item + "itemIndex#" + index);
          let getTxt = item.split("\n");
          //console.log("getTxt#"+getTxt.length);
          getTxt.forEach((_item, index) => {
            if (getTxt.length === 1) {
              if (
                _item.trim().includes("Certainly!") ||
                _item.trim().includes("---") ||
                _item.trim().includes("**")
              ) {
                console.log("index#" + _item.trim());
              } else
                IntroTempArray.push(
                  _item.trim().replace('"', "").replace('"', "")
                );
            } else {
              if (index === 1) {
                IntroTempArray.push(
                  _item.trim().replace('"', "").replace('"', "")
                );
              }
            }
          });
        });
        if (IntroTempArray.length > 0) {
          let shortTxt =
            IntroTempArray[0].trim() +
            "\n\n" +
            IntroTempArray[1].trim() +
            "\n\n" +
            IntroTempArray[2].trim() +
            "\n\n" +
            IntroTempArray[3].trim();
          setShortIntroContent(shortTxt.trim());
          setShowMyFinishCounter((count) => count + 1);
        }
      } else {
        setShortIntroLoader(false);
        setoutput(true);
        setloading(false);
        setShowGenerateBtn(true);
        setShortIntroError(true);
        setShortIntroContent("");
      }

      if (getGenerate === "Generate")
        await SaveFullShortIntro("SHORT_INTRO", introResponseData);
      else setShowMyFinishCounter((count) => count - 1);
    } catch (e) {
      console.log("Error-ShortIntroResponse");
    }
  };

  const ReGenerateConcernGain = async () => {
    try {
      jobAppLang = localStorage.getItem("jobAppLang");
      setShowMyFinishCounter(0);
      let reGenerateConcernobj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        JobPostUserCV: cvTxt,
        JobPostUserCVName: CVFileName,
        ConcernGainType: "Concern",
        Language: jobAppLang,
      };
      setIsSaveConcern(true);
      setfirstlanding(true);
      setListLoader(true);
      setRegenerateDisable(true);
      setIsSaveGain(true);
      setGainListLoader(true);

      const concrenResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetRegenerateConcernGainApi",
        reGenerateConcernobj
      );
      if (concrenResponse.data?.isSuccess) {
        //console.log("concrenResponse#"+concrenResponse.data);
        setRegenerateDisable(false);
        setIsSave(false);
        setListLoader(false);
        setfirstlanding(false);
        setIsSaveConcernFlag(false);
        setIsSaveConcern(true);
        setIsSaveGainFlag(false);
        setIsSaveGain(true);
        setoutput(false);
        setNodata(true);
        populateConcernALL(concrenResponse);
      }

      let reGenerateGainObj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        JobPostUserCV: cvTxt,
        JobPostUserCVName: CVFileName,
        ConcernGainType: "Gain",
        Language: jobAppLang,
      };

      const gainResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetRegenerateConcernGainApi",
        reGenerateGainObj
      );
      console.log("jobPostReponse" + gainResponse.data.jobPostReponse);

      var gainRes = gainResponse.data?.jobPostReponse;
      if (gainResponse.data?.isSuccess) {
        gainRes = removeHash(gainRes);
        if (gainRes.split(splitGainRegex).length > 0) {
          populateGainsALL(gainResponse);
        } else if (gainRes.split("\n\n").length > 0) {
          populateGainsALL(gainResponse);
        }
      } else {
        ReGenerateGain();
        setRegenerateDisable(true);
      }
    } catch (err) {
      setRegenerateDisable(true);
    } finally {
      Mixpanel.track("YsfJobApp:Re-Generate QA Introducation");
    }
  };

  const ReGenerateGain = async () => {
    try {
      jobAppLang = localStorage.getItem("jobAppLang");

      let reGenerateGainObj = {
        UserEmail: user_email,
        JobPostId: jobId.id,
        JobPostUserCV: cvTxt,
        JobPostUserCVName: CVFileName,
        ConcernGainType: "Gain",
        Language: jobAppLang,
      };

      const gainResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetRegenerateConcernGainApi",
        reGenerateGainObj
      );
      console.log("jobPostReponse" + gainResponse.data.jobPostReponse);
      if (gainResponse.data?.isSuccess) populateGainsALL(gainResponse);
    } catch (err) {
      setRegenerateDisable(true);
    } finally {
      Mixpanel.track("YsfJobApp:Re-Generate QA Introducation");
    }
  };

  const funcRegenerateQALongShortIntro = async (_clickTab) => {
    if (_clickTab.includes("QA")) {
      console.log("_clickTab#" + _clickTab);
      TryAgain_GenerateQuestionAnswer();
    } else if (_clickTab.includes("Intro")) {
      console.log("_clickTab#" + _clickTab);
      TryAgain_GenerateIntro();
    } else if (_clickTab.includes("short")) {
      console.log("_clickTab#" + _clickTab);
      TryAgain_GenerateShortIntro();
    }
  };

  const onContentChange = React.useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: ["b", "i", "a", "p", "br"],
      allowedAttributes: { a: ["href"] },
    };
    let sanitizedContent;
    sanitizedContent = evt.currentTarget.innerHTML.replace(/\n/g, "<br>");
    setShortIntroContent(sanitizeHtml(sanitizedContent, sanitizeConf));
  }, []);

  const removeDoubleAsterisks = (strTxt) => {
    return strTxt.replace(/\*\*/g, "");
  };
  const removeHash = (strTxt) => {
    return strTxt.replace(/#/g, "");
  };
  const handleSelect = async (selectedTab) => {
    setClickTab(selectedTab);
    console.log("Selected tab#: " + selectedTab);
    setIntroActiveTab(selectedTab);
    console.log("introActiveTab#: " + introActiveTab);
  };

  return (
    <div
      className="main-layout"
      style={{ background: toggle ? "#F7F9FB" : "#fff" }}
    >
      <div
        className="container-fluid"
        style={{ padding: toggle ? "0" : "0 12px 0 40px" }}
      >
        <div
          className={
            toggle ? "now-gutters concern-l" : "row now-gutters concern-l"
          }
        >
          <div className={toggle ? "col-lg-12" : "col-lg-6"}>
            <div
              className="common-left"
              style={{
                paddingBottom: toggle ? "0" : "50px",
                marginRight: toggle ? "105px" : "0",
              }}
            >
              <div className={toggle ? "pageHeading" : "pageHeading  mb-4"}>
                <h1>
                  <img src={IMAGE.Union} alt="" />
                  Interview
                </h1>
                <ul className="toggle_list_btn">
                  {!toggle ? (
                    <li>
                      <button onClick={() => settoggle(!toggle)}>
                        <img src={IMAGE.toggle_icon} alt="" />
                      </button>
                    </li>
                  ) : (
                    <>
                      <li>
                        <button
                          onClick={() => settoggle(!toggle)}
                          className="backConcernBtn"
                        >
                          <i className="fa-solid fa-arrow-left"></i> See
                          Concerns & Gains
                        </button>
                      </li>
                      {activeTab === "tab1" ? (
                        <li>
                          <button
                            className="tabConcernBtn"
                            onClick={() => setActiveTab("tab2")}
                          >
                            To Introduction
                          </button>
                        </li>
                      ) : (
                        // !isSave && showMyFinishCounter > 2 && (
                        showMyFinishCounter > 2 && (
                          <li>
                            <button
                              className="tabConcernBtn"
                              onClick={btnFinsihInterviewPreparationHandle}
                            >
                              Finish my preparation!
                            </button>
                          </li>
                        )
                      )}
                    </>
                  )}
                </ul>
              </div>
              {isReUploadCV && (
                <div className="cv-replace-sec">
                  <div className="upload-container">
                    <h7>
                      <img src={IMAGE.Attachment} alt="" /> {CVFileName}
                    </h7>
                  </div>
                  <div className="text-end upload-container-right">
                    {cvReUploadLoader ? (
                      <div className="uploadLoader">
                        <h4>
                          <img
                            src={IMAGE.loader_icon}
                            className="LoaderIcon"
                            alt=""
                          />{" "}
                          Uploading...{" "}
                        </h4>
                      </div>
                    ) : (
                      <div className="upload-file">
                        <label>
                          <img src={IMAGE.Refresh_icon} alt="" /> Replace CV
                        </label>
                        <input
                          type="file"
                          accept="application/pdf, application/doc, application/docx"
                          onChange={ReplaceCVHnd}
                        />
                      </div>
                    )}

                    {regenerateDisable && (
                      <button
                        onClick={ReGenerateConcernGain}
                        disabled={
                          CVFileName === "" || regenerateDisable ? false : true
                        }
                      >
                        <img src={IMAGE.generate_icon} alt="" /> Generate{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className={toggle ? "hide c-box" : "c-box"}>
                <div className="concern-box mb-5">
                  <h3>Concerns</h3>
                  {isSaveConcernFlag && !isSave && (
                    <button className="EditBtn" onClick={ConcernEdit}>
                      Edit
                    </button>
                  )}
                  {!isSaveConcernFlag && !isSave && (
                    <h4>
                      Select the reasons <i>{cname}</i> might consider not
                      hiring you
                    </h4>
                  )}
                  {isSaveConcernFlag && (
                    <h4>
                      Here is the summary of <i>{cname}</i> 's relevant concerns
                    </h4>
                  )}
                  {isSave && (
                    <h4>
                      Here is the summary of <i>{cname}</i> 's relevant concerns
                    </h4>
                  )}

                  {isSaveConcern ? (
                    listLoader ? (
                      <ListLoader />
                    ) : (
                      <div className="list-result">
                        <div className="scroll_position">
                          <ul class="ConcernList">
                            {Concernlist &&
                              Concernlist.map((item, i) => {
                                return (
                                  <li
                                    key={i}
                                    onClick={() => ConcernListHandle(item)}
                                    className={
                                      item.toggleSelected ? "selected" : ""
                                    }
                                  >
                                    {item.text}
                                  </li>
                                );
                              })}
                          </ul>

                          <ul className="Added_concern mt-2">
                            {ConcerninputList &&
                              ConcerninputList.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <input
                                      type="text"
                                      value={item.text}
                                      onChange={(e) =>
                                        concerninputHandle(e, "text", index)
                                      }
                                      className="form-control"
                                      placeholder="type your concern"
                                    />
                                  </li>
                                );
                              })}
                            {!isSave && (
                              <li>
                                <button
                                  className="addBtn"
                                  onClick={AddConcernHandle}
                                >
                                  <i className="fa-solid fa-plus"></i> Add your
                                  concerns...
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                        {!isSave && (
                          <div className="text-end mt-4">
                            <button
                              className="saveBtn"
                              onClick={ConcernSave}
                              disabled={showConcernSave === "" ? true : false}
                            >
                              Save{" "}
                              {btnconcernLoader && (
                                <span class="btnloader"></span>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <div className="des-result">
                      <ul>
                        {Concernlist &&
                          Concernlist.map((item, index) => {
                            if (item.toggleSelected) {
                              return <li key={index}>{item.text}</li>;
                            }
                          })}
                      </ul>
                    </div>
                  )}

                  <div className="lft-number">
                    <span className="lft-number-top">1</span>
                    <span
                      className="lft-number-bottom"
                      style={{
                        background: isSave ? "#87C4D0" : "#E6E6E6",
                      }}
                    >
                      {isSave ? "2" : ""}
                    </span>
                  </div>
                </div>

                {firstlanding ? (
                  <div className="concern-box">
                    <h3>Gains</h3>
                    {isSaveGainFlag && !isSave && (
                      <button className="EditBtn" onClick={GainEdit}>
                        Edit
                      </button>
                    )}
                    {isSaveGainFlag && (
                      <h4>
                        Here is the summary of <i>{cname}</i> 's relevant gains
                      </h4>
                    )}
                    {isSave && (
                      <h4>
                        Here is the summary of <i>{cname}</i> 's relevant gains
                      </h4>
                    )}

                    {!isSaveGainFlag && !isSave && (
                      <h4>
                        Select the reasons <i>{cname}</i> might consider hiring
                        you
                      </h4>
                    )}

                    {isSaveGain ? (
                      gainListLoader ? (
                        <ListLoader />
                      ) : (
                        <div className="list-result">
                          <div className="scroll_position">
                            <ul className="ConcernList">
                              {GainList &&
                                GainList.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => GainListHandle(item)}
                                      className={
                                        item.toggleSelected ? "selected" : ""
                                      }
                                    >
                                      {item.text}
                                    </li>
                                  );
                                })}
                            </ul>
                            <ul className="Added_concern mt-2">
                              {GaininputList &&
                                GaininputList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        type="text"
                                        value={item.text}
                                        onChange={(e) =>
                                          gainInputHandle(e, "text", index)
                                        }
                                        className="form-control"
                                        placeholder="type your gain"
                                      />
                                    </li>
                                  );
                                })}
                              {!isSave && (
                                <li>
                                  <button
                                    className="addBtn"
                                    onClick={AddGainHandle}
                                  >
                                    <i className="fa-solid fa-plus"></i> Add
                                    your gain...
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                          {!isSave && (
                            <div className="text-end mt-4">
                              <button
                                className="saveBtn"
                                onClick={GainSave}
                                disabled={showGainSave === "" ? true : false}
                              >
                                Save{" "}
                                {btngainLoader && (
                                  <span class="btnloader"></span>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div className="des-result">
                        <ul>
                          {GainList &&
                            GainList.map((item, index) => {
                              if (item.toggleSelected) {
                                return <li key={index}>{item.text}</li>;
                              }
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="concern-box firstTime">
                    <h3>Gains</h3>
                    <h4>
                      Select the reasons <i>{cname}</i> might consider hiring
                      you
                    </h4>
                  </div>
                )}

                {!showGenerateBtn && (
                  <>
                    {isSaveConcernFlag && isSaveGainFlag && (
                      <div className="text-end mt-4">
                        <button
                          className="generateBtn"
                          onClick={GenerateInterviewIntroducationHandle}
                          disabled={loading ? true : false}
                        >
                          <img src={IMAGE.generate_icon} alt="" /> Generate{" "}
                        </button>
                      </div>
                    )}
                    {isSave && (
                      <div className="text-end mt-4">
                        <button
                          className="collapseBtn"
                          onClick={() => settoggle(!toggle)}
                        >
                          {" "}
                          Collapse this panel{" "}
                        </button>
                      </div>
                    )}
                  </>
                )}
                {showGenerateBtn && (
                  <div className="text-end mt-4">
                    <button
                      className="collapseBtn"
                      onClick={() => settoggle(!toggle)}
                    >
                      {" "}
                      Collapse this panel{" "}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={!toggle ? "col-lg-6 p-0" : "col-lg-12"}>
            <div
              className={
                loading ? "comonn-right justify-content-center" : "comonn-right"
              }
            >
              {nodata && <DefaultLoader toggle={toggle} />}
              {loading && <ConcernLoader />}
              {output && (
                <div className="concern-container-ouput">
                  <ul className="concern-container-ouput-tabs">
                    <li>
                      <button
                        className={
                          activeTab === "tab1" ? "nav-link active" : "nav-link"
                        }
                        onClick={FullVersionIntro}
                      >
                        <img src={IMAGE.Supergroup} alt="" /> Interview
                        questions
                      </button>
                    </li>
                    <li>
                      <button
                        className={
                          activeTab === "tab2" ? "nav-link active" : "nav-link"
                        }
                        onClick={ShortVersionIntro}
                      >
                        <img src={IMAGE.Message_Align_Right} alt="" />{" "}
                        Introduction
                      </button>
                    </li>
                  </ul>
                  <div>
                    {activeTab === "tab1" ? (
                      <>
                        {!questError ? (
                          <ul className="interviewList">
                            {!toggle
                              ? QuestionList &&
                                QuestionList.map((question, index) => {
                                  return (
                                    <li key={index}>
                                      <h3>Question {question?.qno}</h3>
                                      <p>{question?.question}</p>

                                      {question?.suggestedans == "" && (
                                        <div className="text-end">
                                          <button
                                            onClick={() => (
                                              setShow(true),
                                              setIndexIn(index),
                                              setQuestion("")
                                            )}
                                          >
                                            <img
                                              src={IMAGE.union_icon}
                                              alt=""
                                            />{" "}
                                            Give me idea
                                          </button>
                                        </div>
                                      )}
                                      {question?.suggestedans !== "" && (
                                        <div className="not-toogle-answear">
                                          <div className="my_ans">
                                            <h4>My answer </h4>
                                            <p>{question?.suggestedans}</p>
                                          </div>
                                          <div className="text-end">
                                            <button
                                              onClick={() => (
                                                setShow(true),
                                                setIndexIn(index),
                                                setQuestion("")
                                              )}
                                            >
                                              <img
                                                src={IMAGE.union_icon}
                                                alt=""
                                              />{" "}
                                              Edit the answer
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  );
                                })
                              : QuestionList &&
                                QuestionList.map((question, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="row">
                                        <div
                                          className={
                                            question?.suggestedans === ""
                                              ? "col-lg-12"
                                              : "col-lg-5"
                                          }
                                        >
                                          <h3>Question {question?.qno}</h3>
                                          <p>{question?.question}</p>

                                          {question?.suggestedans === "" && (
                                            <div className="text-end">
                                              <button
                                                onClick={() => (
                                                  setShow(true),
                                                  setIndexIn(index),
                                                  setQuestion("")
                                                )}
                                              >
                                                <img
                                                  src={IMAGE.union_icon}
                                                  alt=""
                                                />{" "}
                                                Give me idea
                                              </button>
                                            </div>
                                          )}
                                        </div>

                                        {question?.suggestedans !== "" && (
                                          <div className="col-lg-7">
                                            <div className="my_ans">
                                              <h4>My answer </h4>
                                              <p>{question?.suggestedans}</p>
                                            </div>
                                            <div className="text-end">
                                              <button
                                                onClick={() => (
                                                  setShow(true),
                                                  setIndexIn(index),
                                                  setQuestion("")
                                                )}
                                              >
                                                <img
                                                  src={IMAGE.union_icon}
                                                  alt=""
                                                />{" "}
                                                Edit the answer
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  );
                                })}
                          </ul>
                        ) : (
                          <ul className="interviewList">
                            <li>
                              <div className="noresultFound">
                                <img src={IMAGE.alert_icon} alt="" />
                                <div className="noresultFoundInner">
                                  <h4>Results not generated</h4>
                                  <p>
                                    Oops!!! <br />
                                    Looks like our servers are having a busy
                                    day.
                                    <br /> Please try to generate your results
                                    again.
                                  </p>
                                </div>
                                <button
                                  className="tryBtn"
                                  onClick={TryAgain_GenerateQuestionAnswer}
                                >
                                  Try Again
                                </button>
                              </div>
                            </li>
                          </ul>
                        )}
                        <div className="float-bttn">
                          <button
                            value={clickTab}
                            onClick={() =>
                              funcRegenerateQALongShortIntro(clickTab)
                            }
                          >
                            <img src={IMAGE.generate_icon} alt="" /> Regenerate
                          </button>
                          <button onClick={() => setActiveTab("tab2")}>
                            To Introduction{" "}
                            <img src={IMAGE.right_arrow} alt="" />
                          </button>
                        </div>
                      </>
                    ) : (
                      <Tabs
                        defaultActiveKey="full"
                        className="intoTab"
                        activeKey={introActiveTab}
                        onSelect={handleSelect}
                      >
                        <Tab eventKey="Intro" title="Full version">
                          {introtext ? (
                            <div className="scroll_div">
                              <div
                                className={
                                  !toggle
                                    ? "cover-letter-sep"
                                    : "cover-letter-sep fulllength"
                                }
                              >
                                <div className="left-side">
                                  <h4>Introduction</h4>
                                </div>
                                <div className="right-side">
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {introtext}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={
                                  !toggle
                                    ? "cover-letter-sep"
                                    : "cover-letter-sep fulllength"
                                }
                              >
                                <div className="left-side">
                                  <h4>Body Content</h4>
                                </div>
                                <div className="right-side">
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {bodytext}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={
                                  !toggle
                                    ? "cover-letter-sep"
                                    : "cover-letter-sep fulllength"
                                }
                              >
                                <div className="left-side">
                                  <h4>Conclusion</h4>
                                </div>
                                <div className="right-side">
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {conclusionTxt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Loader title="Full Version Introducation!!" />
                          )}

                          {introError && (
                            <div className="noresultFound">
                              <img src={IMAGE.alert_icon} alt="" />
                              <div className="noresultFoundInner">
                                <h4>Results not generated</h4>
                                <p>
                                  Oops!!! <br />
                                  Looks like our servers are having a busy day.
                                  <br /> Please try to generate your results
                                  again.
                                </p>
                              </div>
                              <button
                                className="tryBtn"
                                onClick={TryAgain_GenerateIntro}
                              >
                                Try Again
                              </button>
                            </div>
                          )}
                          {isSave && (
                            <div className="float-bttn">
                              <button
                                className="generateBtn regenerateBtn"
                                value={clickTab}
                                onClick={() =>
                                  funcRegenerateQALongShortIntro(clickTab)
                                }
                              >
                                <img src={IMAGE.generate_icon} alt="" />{" "}
                                Regenerate
                              </button>
                              <button
                                onClick={() => setIntroActiveTab("short")}
                              >
                                To Short Version{" "}
                                <img src={IMAGE.right_arrow} alt="" />
                              </button>
                            </div>
                          )}
                        </Tab>

                        <Tab eventKey="short" title="Short version">
                          {shortIntroContent && (
                            <ul className="intoduction_list">
                              <li>
                                <div className="intro-top">
                                  <h3>Introduction</h3>
                                  <button onClick={copyShortIntro}>
                                    <img src={IMAGE.copy_icon} alt="Copy" />
                                  </button>
                                  <button>Edit</button>
                                </div>
                                <div className='"editable-container'>
                                  <ContentEditable
                                    style={{ whiteSpace: "pre-wrap" }}
                                    onChange={onContentChange}
                                    onBlur={onContentChange}
                                    html={shortIntroContent}
                                  />
                                </div>
                              </li>
                            </ul>
                          )}

                          {shortIntroLoader && (
                            <ul>
                              <li>
                                <Loader title="Short Version Introducation!!" />
                              </li>
                            </ul>
                          )}

                          {shortIntroError && (
                            <ul>
                              <li>
                                <div className="noresultFound">
                                  <img src={IMAGE.alert_icon} alt="" />
                                  <div className="noresultFoundInner">
                                    <h4>Results not generated</h4>
                                    <p>
                                      Oops!!! <br />
                                      Looks like our servers are having a busy
                                      day.
                                      <br /> Please try to generate your results
                                      again.
                                    </p>
                                  </div>
                                  <button
                                    className="tryBtn"
                                    onClick={TryAgain_GenerateShortIntro}
                                  >
                                    Try Again
                                  </button>
                                </div>
                              </li>
                            </ul>
                          )}

                          {isSave && (
                            <div className="float-bttn">
                              <button
                                value={clickTab}
                                onClick={() =>
                                  funcRegenerateQALongShortIntro(clickTab)
                                }
                              >
                                <img src={IMAGE.generate_icon} alt="" />{" "}
                                Regenerate
                              </button>
                              <button onClick={() => settoggle(!toggle)}>
                                <img src={IMAGE.generate_icon} alt="" />{" "}
                                {toggle
                                  ? "View normal screen"
                                  : "View fullscreen"}
                              </button>
                            </div>
                          )}
                          {showMyFinishCounter > 2 && (
                            <div className="float-bttn">
                              <button
                                value={clickTab}
                                onClick={() =>
                                  funcRegenerateQALongShortIntro(clickTab)
                                }
                              >
                                <img src={IMAGE.generate_icon} alt="" />{" "}
                                Regenerate
                              </button>
                              <button
                                onClick={btnFinsihInterviewPreparationHandle}
                              >
                                Finish my preparation!
                              </button>
                            </div>
                          )}
                        </Tab>
                      </Tabs>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="concern-footer">
        <NavLink to="/home">
          <i className="fa-solid fa-arrow-left"></i> My job list
        </NavLink>
      </div>
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="question-modal"
      >
        <Modal.Body>
          <div className="question-modal-top">
            <button onClick={() => setShow(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <h4>Question {QuestionList[indexin]?.qno} </h4>
            <h5>{QuestionList[indexin]?.question}</h5>
          </div>
          <div className="question-modal-middle">
            <label>Suggested answer </label>
            <p>{QuestionList[indexin]?.ans}</p>
            <label>Prepare your answers</label>
            <button
              className="suggestionBtn"
              onClick={() =>
                InsertHandle(
                  QuestionList[indexin]?.qno,
                  QuestionList[indexin]?.question,
                  QuestionList[indexin]?.ans,
                  "suggestedans",
                  indexin
                )
              }
            >
              Insert suggested answer
            </button>
            <div className="position-relative">
              <textarea
                rows="4"
                className="form-control"
                value={QuestionList[indexin]?.suggestedans}
                onChange={(e) =>
                  AnswerHandle(e.target.value, "suggestedans", indexin)
                }
              ></textarea>
              <button
                className="concernsaveBtn"
                disabled={disableQASave !== "" ? false : true}
                onClick={() =>
                  SaveQuestionAnswerHnd(
                    QuestionList[indexin]?.qno,
                    QuestionList[indexin]?.question,
                    QuestionList[indexin]?.ans,
                    QuestionList[indexin]?.suggestedans
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
          <div className="question-modal-bottom">
            <button
              onClick={() => (setQuestion(""), setIndexIn(indexin - 1))}
              disabled={indexin === 0 ? true : false}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button
              onClick={() => (setQuestion(""), setIndexIn(indexin + 1))}
              disabled={indexin === QuestionList.length - 1 ? true : false}
            >
              Next <i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Concern;
